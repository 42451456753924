import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ForgotPasswordService } from "rl-common/services/forgot-password/forgot-password.service";
import { ModalService } from "rl-common/services/modal.service";
import { ProgressService } from "rl-common/services/progress.service";
import { catchError, finalize } from "rxjs/operators";

@Component({
	templateUrl: "./forgot-password.component.html",
	styleUrls: ["./forgot-password.component.scss"]
})
export class ForgotPasswordComponent implements OnInit {
	public errorMessage: string;
	public forgotPasswordForm: UntypedFormGroup;
	public email: UntypedFormControl;
	public successMessage: string;
	public errorAlertClosed = true;
	public successAlertClosed = true;

	constructor(private readonly formBuilder: UntypedFormBuilder,
		private readonly forgotPasswordService: ForgotPasswordService,
		private readonly progressService: ProgressService,
		private readonly _modalService: ModalService) { }

	ngOnInit() {
		this.setupForm();
	}

	private setupForm() {
		this.email = this.formBuilder.control("", [Validators.required, Validators.email]);

		this.forgotPasswordForm = new UntypedFormGroup({
			email: this.email
		});

		const emailToPopulate = window.history.state.email;

		if (emailToPopulate) {
			this.email.setValue(emailToPopulate);
		}
	}

	public submit() {
		this.errorMessage = null;
		this.successAlertClosed = true;
		this.errorAlertClosed = true;

		if (this.forgotPasswordForm.valid) {
			this.progressService.startProgress();

			this.forgotPasswordService.forgotPassword(this.email.value).pipe(
				catchError(error => {
					this.errorMessage = error.error?.message ?? `Unknown error occurred`;
					this.errorAlertClosed = false;
					throw error;
				}),
				finalize(() => {
					this.progressService.clearProgress();
				})
			).subscribe(() => {
				this.successMessage = `A password reset invitation has been sent to ${this.email.value}.<br><a href="/login">Return to Login</a>`;
				this.successAlertClosed = false;
				this.progressService.endProgress();
			});
		}
	}

	showGdpr() {
		this._modalService.showGdpr();
	}
}
