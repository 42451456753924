import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "templatesBySelectedState"
})
export class TemplatesBySelectedStatePipe implements PipeTransform {
	public transform(list: any[], filterValue: boolean): any[] {
		return list.filter(x => x.isSelected === filterValue);
	}
}
