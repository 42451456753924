<div class="favorites-widget menu-pane">
	<div class="d-flex flex-column">
		<div class="d-flex justify-content-between align-items-center menu-pane__header">
			<h5 class="flex-grow-1 m-0">Quick Links</h5>
			<button *ngIf="!(hasQuickLink$ | async) && !(isAdding || (loading$ | async))" class="btn btn-icon"
				title="Add Quick Link" (click)="addToQuickLinks()" [disabled]="isAdding" [@fadeIn]>
				<i class="far fa-plus"></i>
			</button>
			<div *ngIf="isAdding || (loading$ | async)" [@fadeIn]>
				<i class="far fa-spinner fa-spin"></i>
			</div>
		</div>
		<div class="menu-pane__body">
			<div class="px-3">
				<div class="py-2" *ngIf="!(loading$ | async) && (links$ | async)?.length == 0">
					<em>No links saved.</em>
				</div>
				<div *ngFor="let link of (links$ | async); let isLast = last">
					<div class="d-flex justify-content-between py-1" [class.border-bottom]="!isLast">
						<div class="flex-grow-1 pe-3 pt-1">
							<a [routerLink]="link.url" [title]="link.title">{{link.title}}</a>
						</div>
						<button class="btn btn-sm btn-icon btn-remove" (click)="removeLink(link)"
							title="Delete Quick Link" [disabled]="deleted.has(link.id)">
							<i class="far fa-trash-alt"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>