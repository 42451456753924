import { Acl } from "rl-common/rl-common-acl.consts";

export enum LinkType {
	Ux2 = "ux2",
	Classic = "classic"
}

export interface IAdminLink {
	label: string;
	type: LinkType;
	icon: string;
	cypressAttr: string;
	acl: string;
	route: string;
}

export const AdminLinks: IAdminLink[] = [
	{
		label: "Data Tools",
		type: LinkType.Ux2,
		icon: "fa-list",
		cypressAttr: "DataToolsLink",
		acl: Acl.Activities.ActivityAdminAcls.DataTools,
		route: "/data-tools/"
	},
	{
		label: "Administration",
		type: LinkType.Ux2,
		icon: "fa-wrench",
		cypressAttr: "CompanyAdminLink",
		acl: Acl.Activities.ActivityAdminAcls.Administration,
		route: "/administration/"
	},
	{
		label: "Configuration",
		type: LinkType.Classic,
		icon: "fa-cogs",
		cypressAttr: "ConfigurationLink",
		acl: Acl.Activities.ActivityAdminAcls.Configuration,
		route: "/configuration"
	},
];

export interface IComponentListPageReadAccess {
	amounts?: boolean;
	documents?: boolean;
	rights?: boolean;
	tables?: boolean;
}