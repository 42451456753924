import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular-ivy";
import { ConstUtils } from "rl-common/consts";
import { IVersionInfo } from "rl-common/services/version/version.models";
import { RLAppModule } from "./app/rightsline-app/rl-app.module";
import version from "./assets/version.json";
import { environment } from "./environments/environment";

const hostName = window.location.hostname;
const versionInfo: IVersionInfo = version;
const isLocal = ConstUtils.isLocal(hostName);
const releaseName = ConstUtils.getReleaseName(versionInfo, hostName);

let sampleRate = .05;
// We bump up the sample rate up for lowers because they don't get as much traffic
switch (environment.name) {
	case "dev":
	case "staging":
	case "qa":
		sampleRate = .1;
		break;
}

Sentry.init({
	enabled: !isLocal,
	dsn: "https://bff022cca553458796560e6a5b1b57d9@o1039064.ingest.sentry.io/6007786",
	integrations: [],
	sampleRate,
	release: releaseName,
	environment: environment.name,
	ignoreErrors: [
		/^Non-Error exception captured with keys.*/,
		/.*ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.*/,
		/.*pendo is not defined.*/,
		/.*Can't find variable: pendo.*/,
		/.*No error message.*/,
		/^Http failure response for .*: 503.*/, // Maintenance response code
		/^Http failure response for .*: 401 Unauthorized$/, // Unauthorized
		/^Http failure response for .*: 403.*/, // Forbidden
		/^Http failure response for .*: 404.*/, // Not Found
		/^Http failure response for .*: 428.*/, // Bad Config
		/^Http failure response for .*: 0 Unknown Error$/, // CORS error
		/^Non-Error promise rejection captured with value: Object Not Found Matching Id:\d+$/,
		/Loading chunk [\d]+ failed/,
		/^Non-Error promise rejection captured with keys: abort, always, catch, done, fail$/,
		/^Error: FS-Cannot upload file part\s*$/, //  happens when FS upload is canceled
		/^unmountComponentAtNode\(\.\.\.\): Target container is not a DOM element\.$/, // react exception (izenda)
		/^Uncaught \(in promise\): OpenFailedError: InvalidStateError.*/,
		/^Internal error opening backing store for indexedDB\.open\..*/,
		/InvalidStateError: A mutation operation was attempted on a database that did not allow mutations\./,
		/InvalidStateError: Failed to execute 'abort' on 'IDBTransaction': The transaction has finished\./,
		/Can't create duplicate variable:/, // Safari shenanigans
		/^22P01: floating-point exception$/, // Postgres floating point exception (rights explorer)
		/^Method not implemented\./,
		/^ResizeObserver loop limit exceeded/, // https://github.com/WICG/resize-observer/issues/38
		/^53300: sorry, too many clients already/ // Postgres too many clients
	],
	denyUrls: [
		// Chrome extensions
		/extensions\//i,
		/^chrome:\/\//i,
	],
	maxBreadcrumbs: 20,
	// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
	beforeSend(event, hint) {
		try {
			const ignoredBrowsers = ["firefox"];
			const userAgent = navigator.userAgent.toLowerCase();
			const isIgnoredBrowser = ignoredBrowsers.filter(x => userAgent.includes(x)).length > 0;
			if (isIgnoredBrowser) {
				return null;
			}

			const ignoreFiles = [
				`/assets/izenda/izenda_ui.js`,
				`/assets/izenda/izenda_vendors.js`,
				`/pendo.js`,
				`/react/docs/error-decoder.html` // izenda uses react
			];

			const stackFrames = event.exception?.values[0]?.stacktrace?.frames ?? [];
			if (stackFrames.length > 0) {
				const firstFrame = stackFrames[0];
				const lastFrame = stackFrames[stackFrames.length - 1];

				// https://github.com/getsentry/sentry-javascript/issues/3147
				if (firstFrame?.filename === `<anonymous>`) {
					return null;
				}

				const fileName = lastFrame?.filename;
				if (fileName && ignoreFiles.some(x => fileName.endsWith(x))) {
					return null; // don't report this exception
				}
			}

		} catch (e) { } // It's fine to swallow here, since returning the event itself means the exception will be reported to Sentry

		return event;
	}
});

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic().bootstrapModule(RLAppModule);
