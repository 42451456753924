import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { SearchFieldNames } from "rl-common/components/entities/entity-search/query.models";
import { GridDataSourceBuilder } from "rl-common/components/grid/datasource/builders/grid-datasource-builder";
import { BitmapDataSelectStrategy } from "rl-common/components/grid/datasource/data-select/bitmap-data-select.strategy";
import { EntitySearchDataSource } from "rl-common/components/grid/datasource/search/entity-search.datasource";
import { GridTableComponent } from "rl-common/components/grid/grid-table/grid-table.component";
import { BulkEditsPackage, GridCellError, GridColumn, GridOptions } from "rl-common/components/grid/grid.models";
import { ICharacteristicData, IEntityRecTemplateCharData, IEntitySearchDoc, IQueryNode } from "rl-common/models";
import { BitmapAllocatorService } from "rl-common/services/bitmap-allocator/bitmap-allocator.service";
import { IModsLinks, LinkHelperService } from "rl-common/services/link-helper.service";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { QueryUtil } from "rl-common/utils";
import { Observable, Subscription, of } from "rxjs";
import { map, tap } from "rxjs/operators";


@Component({
	selector: "rl-grid-test5",
	templateUrl: "./grid-test5.component.html",
	providers: [BitmapAllocatorService]
})
export class GridTest5Component implements OnInit, OnDestroy {

	@ViewChild("wrapper", { static: true })
	wrapperElRef: ElementRef<HTMLElement>;

	@ViewChild("handle", { static: true })
	handleElRef: ElementRef<HTMLElement>;

	@ViewChild("grid", { static: true })
	grid: GridTableComponent<IEntityRecTemplateCharData>;

	modLinkHelper: IModsLinks;

	charTypeID = 3;

	columns: GridColumn<IEntityRecTemplateCharData>[] = [
		{
			key: "id",
			headerName: "ID",
			renderer: "link_id",
			width: "min-content",
			getCellData: (doc) => doc,
			locked: true,
			sortKey: "recordid"
		}
	];
	gridOptions: GridOptions<IEntityRecTemplateCharData> = {
		defaultGetCellDataFn: (column, tuple) => {
			switch (column.key) {
				case "id":
					return tuple.record.recordID;
				default:
					return "dummy data";
			}
		}		// bodyHeight: "minmax(auto, 300px)",
	};

	dataSource: EntitySearchDataSource<IEntitySearchDoc, unknown>;
	dataSelectStrategy: BitmapDataSelectStrategy<IEntitySearchDoc>;
	selectState$: Observable<any>;
	bitmapSerialized: string;


	bulkEditsPackage: BulkEditsPackage<ICharacteristicData[]>;

	isValid: boolean;
	rowErrors: GridCellError[];

	subs: Subscription[] = [];

	constructor(
		private linkHelper: LinkHelperService,
		private oneConfigService: OneConfigService,
		private gridDataSourceBuidler: GridDataSourceBuilder,
		private readonly _bitmapAllocator: BitmapAllocatorService
	) {
	}

	ngOnInit() {
		this.modLinkHelper = this.linkHelper.mods[1];

		const wrapper = this.wrapperElRef.nativeElement;
		const handle = this.handleElRef.nativeElement;
		const documentEl = handle.ownerDocument;
		const mousemove = (event: MouseEvent) => {
			wrapper.style.height = wrapper.getBoundingClientRect().height + event.movementY + "px";
		};
		const mouseup = () => {
			documentEl.removeEventListener("mousemove", mousemove);
			documentEl.removeEventListener("mousemove", mouseup);
			this.grid.calculateLayout();
		};

		handle.addEventListener("mousedown", () => {
			documentEl.addEventListener("mousemove", mousemove);
			documentEl.addEventListener("mouseup", mouseup);
		});

		this.dataSelectStrategy = this.gridDataSourceBuidler.dataSelectStrategies.bitmapDataSelectStrategy<IEntitySearchDoc>(this._bitmapAllocator, (rowData) => rowData.recordID, (rowData) => rowData.recordID)
			.setRequired(true);


		this.selectState$ = this.dataSelectStrategy.selectStateBitmapChange$.pipe(
			tap((state) => {
				this.bitmapSerialized = state.selectedIdsBitmap;
			}),
			map((state) => ({
				isAllSelected: state.isAllSelected,
				selectedIds: state.selectedIdsBitmap != null ? [...state.selectedIdsBitmap] : [],
				selectedValues: []
			})));

		const columnSelectStrategy = this.gridDataSourceBuidler.columnStrategies.searchDocColumnStrategy(this.charTypeID, null, null);

		this.dataSource = this.gridDataSourceBuidler.entitySearchDataSource(this.charTypeID)
			.withProgress(true)
			.withDataSelectStrategy(this.dataSelectStrategy)
			.withColumnStrategy(columnSelectStrategy)
			.withDragDropAction((ds, $event) => {
				console.info($event);
				return of(null);
			})
			.withSelectableIdsBitmap(true, QueryUtil.$eq(SearchFieldNames.Entity.activeIndicator, 1)) // active only
			.setSorting({ sortDir: 0, sortKey: "status_updated" })
			.setPaging({ rowOffset: 0, pageSize: 25 });

		// const sub1 = merge(this.dataSource.modifiedRowDatas$, this.dataSource.modifiedColumnDatas$)
		// 	.subscribe(() => {
		// 		this.bulkEditsPackage = dataChangeStrategy.makeCharDataBulkEditPackage(columnSelectStrategy.cmds);
		// 	});

		const sub2 = this.dataSource.rowErrors$
			.subscribe((errors) => {
				setTimeout(() => {
					this.isValid = this.dataSource.formGroup.valid;
					this.rowErrors = Array.from(errors);
				});
			});

		this.subs.push(sub2);

		this.search();
	}

	search() {
		const query: IQueryNode = {};
		this.dataSource
			.setQueryNode(query)
			.fetchRows()
			.subscribe();
	}



	ngOnDestroy(): void {
		// Called once, before the instance is destroyed.
		// Add 'implements OnDestroy' to the class.
		this.subs.forEach(s => s.unsubscribe());
	}

	toggleColumnEdit() {
		const enabled = this.dataSource.columnEditToolsEnabled$.value;
		this.dataSource.columnEditToolsEnabled$.next(!enabled);
	}
}
