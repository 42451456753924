import { Component, OnInit } from "@angular/core";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "rl-job-modal",
  templateUrl: "./job-modal.component.html",
  styleUrls: ["./job-modal.component.scss"]
})
export class JobModalComponent implements OnInit {

  constructor(
    public readonly activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

  public endJob(): void {
    this.activeModal.close();
  }
}
