import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { chain, range } from "lodash";
import { GridDataSourceBuilder } from "rl-common/components/grid/datasource/builders/grid-datasource-builder";
import { CommonGridDataSource } from "rl-common/components/grid/datasource/common-grid.datasource";
import { GridTableComponent } from "rl-common/components/grid/grid-table/grid-table.component";
import { GridColumn, GridOptions } from "rl-common/components/grid/grid.models";
import { IEntitySearchDoc, IQueryNode } from "rl-common/models";
import { IGridViewAssocEntityRec } from "rl-common/services/grid-view/grid-view.models";
import { IModsLinks, LinkHelperService } from "rl-common/services/link-helper.service";
import { Observable, of } from "rxjs";
import { delay } from "rxjs/operators";



@Component({
	selector: "rl-grid-test4",
	templateUrl: "./grid-test4.component.html"
})
export class GridTest4Component implements OnInit, OnDestroy {

	@ViewChild("wrapper", { static: true })
	wrapperElRef: ElementRef<HTMLElement>;

	@ViewChild("handle", { static: true })
	handleElRef: ElementRef<HTMLElement>;

	@ViewChild("grid", { static: true })
	grid: GridTableComponent;

	modLinkHelper: IModsLinks;

	charTypeID = 1;

	columns: GridColumn<IGridViewAssocEntityRec>[] = [
		{
			key: "id",
			headerName: "ID",
			renderer: "text",
			width: "min-content",
			getCellData: (rec) => rec.recordId,
			locked: true
		},
		{
			key: "name",
			headerName: "Name",
			width: "max-content",
			renderer: "text",
			getCellData: (rec) => rec.title,
			showHierarchy: true
		},
		{
			key: "chartype",
			headerName: "chartype",
			width: "auto",
			renderer: "text",
			getCellData: (rec) => rec.charTypeId
		},
		{
			key: "random",
			headerName: "Random",
			width: "auto",
			renderer: "text",
			getCellData: (rec) => Math.random() * 1000000
		}
	];
	gridOptions: GridOptions<IEntitySearchDoc> = {
		// bodyHeight: "minmax(auto, 300px)",
	};

	dataSource: CommonGridDataSource<IGridViewAssocEntityRec>;
	selectState$: Observable<any>;

	constructor(
		private linkHelper: LinkHelperService,
		private gridDataSourceBuilder: GridDataSourceBuilder
	) { }

	ngOnInit() {

		const rows = range(0, 100).map((i) => (<IGridViewAssocEntityRec>{ recordId: i, charTypeId: 1, title: `Psuedorandom row ${i}` }));


		this.dataSource = this.gridDataSourceBuilder.commonGridDataSource<IGridViewAssocEntityRec>(row => row.recordId)
			.setColumns(this.columns)
			.setPaging({ pageSize: 10 })
			.setSorting({ sortKey: "id", sortDir: 0 })
			.withFetchFn((ds) => {
				const data = chain(rows).slice(ds.rowOffset$.value).take(ds.pageSize$.value).value();
				return of({ rowData: data, rowCount: rows.length }).pipe(delay(1500));
			});

		// const hierarchyStrategy = this.gridDataSourceBuilder.hierarchyStrategies
		// 	.entitySearchDocHierarchyStrategy(this.charTypeID, this.charTypeID);

		// const viewColumnStrategy = this.gridDataSourceBuilder.columnStrategies.searchDocColumnStrategy(this.charTypeID);

		// this.dataSource
		// 	// .setColumns(this.columns)
		// 	.setSorting({ sortKey: "status_updated", sortDir: 0 })
		// 	.setPaging({ pageSize: 25 })
		// 	.withHierarchyStrategy(hierarchyStrategy)
		// 	.withColumnStrategy(viewColumnStrategy);

		// this.selectState$ = this.dataSource.dataSelectStrategy.selectStateChange$
		// 	.pipe(
		// 		map((state) => ({
		// 			isAllSelected: state.isAllSelected,
		// 			selectedIds: Array.from(state.selectedIds),
		// 			selectedValues: Array.from(state.selectedValues)
		// 		}))
		// 	);

		this.modLinkHelper = this.linkHelper.mods[1];
		this.search();

		// this.ngZone.runOutsideAngular(() => {
		const wrapper = this.wrapperElRef.nativeElement;
		const handle = this.handleElRef.nativeElement;
		const documentEl = handle.ownerDocument;
		const mousemove = (event: MouseEvent) => {
			wrapper.style.height = wrapper.getBoundingClientRect().height + event.movementY + "px";
		};
		const mouseup = () => {
			documentEl.removeEventListener("mousemove", mousemove);
			documentEl.removeEventListener("mousemove", mouseup);
			this.grid.calculateLayout();
		};

		handle.addEventListener("mousedown", () => {
			documentEl.addEventListener("mousemove", mousemove);
			documentEl.addEventListener("mouseup", mouseup);
		});
		// });
	}

	search() {

		const query: IQueryNode = {};
		this.dataSource
			// .setQueryNode(query)
			.fetchRows()
			.subscribe();
	}

	ngOnDestroy(): void {
		// Called once, before the instance is destroyed.
		// Add 'implements OnDestroy' to the class.
	}
}
