<div class="login-brand text-center">
	<img src="/assets/img/rightsline-logo_prpl_180w.png" />
</div>

<div class="card mb-5">
	<div class="card-header">
		<h1 class="text-center">Forgot Password</h1>
	</div>
	<div class="card-body p-5">
		<ngb-alert type="danger" *ngIf="errorMessage && !errorAlertClosed" [dismissible]="true"
			(close)="errorAlertClosed=true">
			<span [innerText]="errorMessage"></span>
		</ngb-alert>
		<ngb-alert type="success" *ngIf="successMessage && !successAlertClosed" [dismissible]="true"
			(close)="successAlertClosed=true">
			<span [innerHtml]="successMessage"></span>
		</ngb-alert>
		<form (ngSubmit)="submit()">
			<div class="mb-2" hasError>
				<label class="form-control-label">Email</label>
				<input type="text" class="form-control" name="email" [formControl]="email" />
			</div>
			<div class="mb-2 text-end">
				<button type="submit" class="btn btn-success" [disabled]="!forgotPasswordForm.valid" rlBusyButton>
					Send Email
				</button>
			</div>
			<div *ngIf="!successAlertClosed">
				<h5>Didn't Receive an Email?</h5>
				<ul>
					<li>Check the spam/junk mail folder of <strong>{{email.value}}</strong> for any mail sent from
						<strong>info&#64;rightsline.com</strong> or the subject line <strong>Rightsline Password Reset
							Request</strong>.
					</li>
					<li>Whitelist <strong>info&#64;rightsline.com</strong> and attempt to resend the invitation.</li>
				</ul>
			</div>
		</form>
	</div>
	<div class="card-footer text-center">
		<button class="btn btn-sm btn-link" (click)="showGdpr()">GDPR &amp; PII Information</button> |
		<a class="btn btn-sm btn-link" href="https://www.rightsline.com/privacy-policy/" target="_blank"
			rel="noopener">Privacy Policy</a> |
		<a class="btn btn-sm btn-link" href="https://www.rightsline.com/terms-conditions/" target="_blank"
			rel="noopener">Terms &amp; Conditions</a>
	</div>
</div>