<div class="mx-3">
	<div class="dashboard-panel-head d-flex justify-content-between align-items-center">
		<h3 class="m-0">
			{{reportName}}
			<a [routerLink]="linkHelper.izenda.reportViewer(reportId)" target="_blank">
				<i class="far text-small fa-external-link ms-1"></i>
			</a>
		</h3>
		<button class="btn btn-remove ms-2" (click)="removeFromDashboard()" title="Remove Report from Dashboard">
			<i class="far fa-times"></i>
		</button>
	</div>
	<rl-izenda-report [reportId]="reportId" [hideToolbar]="true" [hideFilter]="true" [hideReportName]="true"
		[hidePreviewRecords]="true"></rl-izenda-report>
</div>