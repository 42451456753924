import { Injectable } from "@angular/core";
import { FlexDashboardConstants } from "app/rightsline-app/components/dashboard/flex-dashboard.consts";
import { IWidgetOptions, Widgets, WidgetSize } from "app/rightsline-app/components/dashboard/widget/widget.models";
import { cloneDeep } from "lodash";
import { combineLatest, Subject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { v4 } from "uuid";
import { AdvancedSearchService } from "../advanced-search/advanced-search.service";
import { RlHttpClient } from "../rl-http-client.service";
import { IFlexDashboard } from "./dashboard.models";


@Injectable({
	providedIn: "root"
})
export class DashboardService {

	public removeWidget$ = new Subject<string>();
	public updateWidgetParams$ = new Subject<[string, any]>();

	constructor(
		private readonly _advancedSearchService: AdvancedSearchService,
		private readonly _httpClient: RlHttpClient) { }

	removeWidget(widgetId: string) {
		if (!widgetId) {
			console.warn(`widgetId: ${widgetId} is not valid.`);
			return;
		}
		this.removeWidget$.next(widgetId);
	}

	updateWidgetParams(widgetId: string, params: any) {
		if (!widgetId) {
			console.warn(`widgetId: ${widgetId} is not valid.`);
			return;
		}
		this.updateWidgetParams$.next([widgetId, params]);
	}

	getDashboards() {
		return this._httpClient
			.get(`/dashboard`)
			.fetch<IFlexDashboard[]>();
	}

	saveDashboard(dashboard: IFlexDashboard) {
		return this._httpClient
			.post(`/dashboard`)
			.body(dashboard)
			.fetch<IFlexDashboard>().pipe(
				tap(d => {
					d.widgets.forEach(w => w.id = v4());
				})
			);
	}

	deleteDashboard(dashboardId: string) {
		return this._httpClient
			.delete(`/dashboard/${dashboardId}`)
			.fetch<void>();
	}

	private defaultDashboard(): IFlexDashboard {
		return {
			id: null,
			name: `Default Dashboard`,
			widgets: cloneDeep(FlexDashboardConstants.DefaultDashboard)
		};
	}

	getDashboard() {
		const dashboard$ = this.getDashboards().pipe(
			map(dashboards => {
				const defaultDashboard = this.defaultDashboard();
				if (dashboards.length <= 0) {
					return defaultDashboard;
				}
				const dashboard = dashboards[0];
				const missingDefaultWidgets = defaultDashboard.widgets.filter(widget => {
					const found = dashboard.widgets.find(x => x.type === widget.type);
					return !found;
				});
				dashboard.widgets.concat(missingDefaultWidgets);
				return dashboard;
			})
		);

		return combineLatest([dashboard$, this._advancedSearchService.getDashboardSearches()]).pipe(
			map(([dashboard, savedSearches]) => {
				const savedSearchWidgets = savedSearches.map(x => {
					const widget: IWidgetOptions = {
						type: Widgets.SavedSearch,
						size: WidgetSize.Two,
						params: x
					};
					return widget;
				});

				let widgets = cloneDeep(dashboard.widgets);

				// finds the saved search widgets that are missing
				const missingSavedSearchWidgets = savedSearchWidgets.filter(searchWidget => {
					const found = widgets.find(x => x.type === Widgets.SavedSearch && x.params.id === searchWidget.params.id);
					if (found) {
						// If found, we should update the params
						found.params = searchWidget.params;
					}
					return !found;
				});

				// removes any saved search widgets, which are no longer saved to the dashboard
				widgets = widgets.filter(w => w.type !== Widgets.SavedSearch || savedSearchWidgets.find(s => w.params.id === s.params.id));
				widgets = widgets.concat(missingSavedSearchWidgets);

				// massages the widget data
				widgets.forEach(w => {
					w.id = v4();

					// TODO: temporary fix until we can make widgets resizable
					if (w.type === Widgets.SavedSearch) {
						w.size = WidgetSize.Two;
					}

				});

				dashboard.widgets = widgets;
				return dashboard;
			})
		);
	}
}
