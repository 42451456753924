import { animate, query, style, transition, trigger, useAnimation } from "@angular/animations";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { GrowlerService } from "rl-common/services/growler.service";
import { Subscription } from "rxjs";
import { tap } from "rxjs/operators";
import { animationTransitionOpacity } from "../../../common/components/animations/animations";


interface IAlert {
	id: number;
	classNames: string[];
	title: string;
	message: string;
	allowDismiss: boolean;
}

@Component({
	selector: "rl-growl-notifications",
	templateUrl: "./growl-notifications.component.html",
	styleUrls: ["./growl-notifications.component.scss"],
	animations: [
		trigger('slideAnimation', [
			transition('* <=> *', [
				query(".alert:enter", 
					[
						useAnimation(animationTransitionOpacity, {
							params: {
								opacityStart: 0,
								opacityEnd: 1,
								time: '300ms ease-out'
							}
						})
					],
					{ optional: true }
				)
			]),
			transition(':leave', [
				query(".alert:leave", 
					[
						useAnimation(animationTransitionOpacity, {
							params: {
								opacityStart: 1,
								opacityEnd: 0,
								time: '300ms ease-out'
							}
						})
					],
					{ optional: true }
				)
			])
		])
	]
})
export class GrowlNotificationsComponent implements OnInit, OnDestroy {

	private readonly _subs: Subscription[] = [];

	alerts: IAlert[] = [];

	private _id = 0;
	private readonly _timeoutMs = 5_000;
	private _paused = false;

	constructor(private readonly _growlerService: GrowlerService) { }

	ngOnInit(): void {
		const sub = this._growlerService.growl$
			.pipe(
				tap((growler) => {
					const classNames: string[] = [];

					switch (growler.growlType) {
						case "error":
							classNames.push("alert-danger");
							break;
						case "info":
							classNames.push("alert-info");
							break;
						case "success":
							classNames.push("alert-success");
							break;
						case "warning":
							classNames.push("alert-warning");
							break;
						default:
							break;
					}

					if (growler.allowDismiss) {
						classNames.push("alert-dismissible");
					}

					const alert: IAlert = {
						id: this._id++,
						allowDismiss: growler.allowDismiss,
						classNames: classNames,
						title: growler.title,
						message: growler.message
					};

					this.setTimeout(alert);

					this.alerts.push(alert);
				})
			)
			.subscribe();

		this._subs.push(sub);
	}

	ngOnDestroy() {
		this._subs.forEach(s => s.unsubscribe());
	}

	trackBy(i: number, alert: IAlert) {
		return alert.id;
	}

	dismiss(alert: IAlert) {
		this.alerts = this.alerts.filter(a => a.id !== alert.id);
	}

	private setTimeout(alert: IAlert) {
		setTimeout(() => {
			if (this._paused) {
				this.setTimeout(alert);
			} else {
				this.dismiss(alert);
			}
		}, this._timeoutMs);
	}

	pause() {
		this._paused = true;
	}

	unpause() {
		this._paused = false;
	}
}
