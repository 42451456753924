<div *ngIf="!actionsLoading" class="d-flex justify-content-center m-3">
	<div class="card col-sm-10 col-md-8 p-0">
		<div class="card-body">
			<div class="card-title border-bottom pb-2 mb-3">
				<h3>{{pageHeader}}</h3>
			</div>

			<div class="char-data border-bottom pb-1 mb-3">
				<div class="char-data__group">
					<div>
						<div *ngIf="!workflowExecuteError">
							<div class=" char-data__group__element">
								<div class="char-data__label">
									<span [innerText]="charTypeName"></span>
								</div>
								<div class="char-data__control container m-0">
									<div class="row">
										<span [innerText]="recordId"></span>
									</div>
								</div>
							</div>
							<div class="char-data__group__element">
								<div class="char-data__label">
									<span>Title</span>
								</div>
								<div class="char-data__control container m-0">
									<div class="row">
										<span [innerText]="charTitle"></span>
									</div>
								</div>
							</div>
							<div class="char-data__group__element">
								<div class="char-data__label">
									<span>Current Status</span>
								</div>
								<div class="char-data__control container m-0">
									<div class="row">
										<span [innerText]="charStatus"></span>
									</div>
								</div>
							</div>
							<div class="char-data__group__element" *ngIf="validationError">
								<div class="char-data__label">
									<span>Validation Message</span>
								</div>
								<div class="char-data__control container m-0">
									<div class="row">
										<span [innerText]="validationError"></span>
									</div>
								</div>
							</div>
						</div>
						<div class="char-data__group__element" *ngIf="workflowExecuteError">
							<div class="char-data__label">
								<span>Error</span>
							</div>
							<div class="char-data__control container m-0">
								<div class="row">
									<span [innerText]="workflowExecuteError"></span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div *ngIf="availableWorkflowActions && showWorkflowActions">
				<div *ngFor="let action of availableWorkflowActions">
					<div class="d-inline-flex mt-3 ms-3">
						<input class="form-check-input" type="radio" id="action.actionId" name="workflowSelection"
							(change)="changeWorkflowAction(action.actionId)" />
						<label class="form-check-label ms-1 text-nowrap"
							for="action.actionId">{{action.actionName}}</label>
					</div>
				</div>
				<div class="d-flex justify-content-end border-top mt-4 pt-3">
					<button class="btn btn-success" (click)="executeWorkflow()"
						[disabled]="executeDisabled">Execute</button>
				</div>
			</div>

			<div *ngIf="workflowActionSuccess">
				<div class="d-inline-flex mt-3 ms-3">
					<div class="card-title pb-2 mb-3">
						<h3 class="text-success">Workflow action completed successfully.</h3>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="text-center p-3" *ngIf="isLoading">
	<rl-loader></rl-loader>
</div>