export interface LoginOptionsResponse {
	success: boolean;
	message: string;
	showPassword: boolean;
	showSSO: boolean;
	ssoEndpoint: string;
	ssoAuthNRequest: string;
	ssoAppUrlID: number;
	redirectToClassic: boolean;
}

export enum LoginSteps {
	LoginOptions = "login-options",
	EnterPassword = "enter-password",
	TwoFactor = "two-factor",
	ChooseApp = "choose-app",
	Maintenance = "maintenance"
}

export interface IOauthError {
	authError: string;
	authErrorDescription: string;
}
