<div class="p-3" style="background-color:white;">
	<div #wrapper>
		<rl-grid #grid [options]="gridOptions" [dataSource]="dataSource">
			<ng-container *rlGridRowHoverOps="let rowData">
				<button class="btn btn-sm btn-light" [title]="'edit ' + rowData.title">
					<i class="far fa-edit"></i></button>
				<button class="btn btn-sm btn-light text-danger" [title]="'delete ' + rowData.title">
					<i class="far fa-trash-alt"></i></button>
			</ng-container>
		</rl-grid>
	</div>
	<button class="btn btn-icon btn-info" #handle style="float:right;cursor: ns-resize;">
		<i class="far fa-ellipsis-h"></i>
	</button>
</div>
<div class="p-3">
	<div class="row">
		<div class="col">
			<div class="card">
				<div class="card-header">Selected Data</div>
				<div class="card-body">
					<pre><code>{{selectState$ | async |json}}</code></pre>
				</div>
			</div>
		</div>
		<div class="col">
			<div class="card">
				<div class="card-header">Grid Stuff</div>
				<div class="card-body">
					<button type="button" class="btn btn-primary" (click)="grid.toggleFullScreen()">Toggle
						Fullscreen</button>
				</div>
			</div>
		</div>
	</div>

</div>