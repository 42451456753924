import { Component, OnInit } from "@angular/core";
import { EntityAuditHistoryService } from "rl-common/services/entity-audit-history/entity-audit-history.service";
import { LinkHelperService } from "rl-common/services/link-helper.service";

@Component({
	selector: "rl-recent-activity-widget",
	templateUrl: "./recent-activity-widget.component.html",
	styleUrls: ["./recent-activity-widget.component.scss"]
})
export class RecentActivityWidgetComponent implements OnInit {

	recentActivities$ = this._auditHistoryService.recentActivity(5);

	constructor(
		private readonly _auditHistoryService: EntityAuditHistoryService,
		public readonly _linkHelper: LinkHelperService
	) { }

	ngOnInit() {
	}
}
