<div class="mx-3">
	<div class="dashboard-panel-head d-flex justify-content-between align-items-center">
		<h3 class="m-0">Deal Status</h3>
		<div [hidden]="isLoading" ngbDropdown autoClose="outside" placement="bottom-right"
			#dealsByStatusDropdown="ngbDropdown">
			<button class="btn btn-link btn-sm" ngbDropdownToggle role="button">Show</button>
			<div ngbDropdownMenu>
				<h6 class="mx-2">Select up to 4 Templates</h6>
				<div class="template--selected bg-light p-2">
					<div *ngFor="let templateItem of templates | templatesBySelectedState:true">
						<label class="form-check-label d-flex justify-content-between align-items-center"
							(click)="removeSelectedTemplate(templateItem)">
							<span class="btn-remove"><i class="far fa-times me-2"></i>
								{{templateItem.templateName}}</span>
							<span class="text-muted ps-2">{{templateItem.dealCount}}</span>
						</label>
					</div>
				</div>
				<div class="template--list mt-2 ms-2">
					<div *ngIf="(templates | templatesBySelectedState:false) as templates">
						<div *ngIf="templates.length > 0">
							<div class="form-check" *ngFor="let templateItem of templates"
								[class.text-muted]="selectedTemplateLimitReached">
								<label class="form-check-label d-flex justify-content-between pb-1">
									<span class="flex-grow">
										<input type="checkbox" class="form-check-input me-1"
											[value]="templateItem.templateID" [disabled]="selectedTemplateLimitReached"
											(click)="addSelectedTemplate(templateItem)">
										{{templateItem.templateName}}
									</span>
									<span class="text-muted px-2">{{templateItem.dealCount}}</span>
								</label>
							</div>
						</div>
						<div *ngIf="templates.length === 0">
							<em>No Deal Templates exist.</em>
						</div>
					</div>
				</div>
				<div class="dropdown-divider"></div>
				<div class="text-end me-2">
					<button class="btn btn-light btn-sm me-2" (click)="cancelTemplateSelection()">Cancel</button>
					<button class="btn btn-info btn-sm me-2" (click)="resetTemplateSelection()">Reset</button>
					<button class="btn btn-success btn-sm"
						(click)="loadDealsByStatusForSelectedTemplates()">Apply</button>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="!isLoading; else loading">
		<div *ngIf="appliedTemplates.length > 0">
			<div class="row mt-5">
				<div [ngClass]="'col-md-' + (12 / appliedTemplates.length)"
					*ngFor="let templateItem of appliedTemplates">
					<rl-donut-chart-component [chartData]="getChartData(templateItem)"></rl-donut-chart-component>
				</div>
			</div>
		</div>
		<div class="row" *ngIf="appliedTemplates.length === 0">
			<div class="col">
				Please select up to {{selectedTemplateLimit}} templates.
			</div>
		</div>
	</div>
</div>

<ng-template #loading>
	<div class="my-3 text-center">
		<rl-loader></rl-loader>
	</div>
</ng-template>