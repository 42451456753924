import { Component, Inject, OnInit } from "@angular/core";
import { WidgetConstants } from "app/rightsline-app/components/dashboard/widget/widget.const";
import { IWidgetOptions } from "app/rightsline-app/components/dashboard/widget/widget.models";
import { DashboardService } from "rl-common/services/dashboard/dashboard.service";
import { LinkHelperService } from "../../../services/link-helper.service";

export interface IzendaReportWidgetParams {
	reportId: string;
	reportName: string;
}

@Component({
	selector: "rl-izenda-report-widget",
	templateUrl: "./izenda-report-widget.component.html",
	styleUrls: ["./izenda-report-widget.component.scss"]
})
export class IzendaReportWidgetComponent implements OnInit {

	reportId: string;
	reportName: string;
	widgetId: string;

	constructor(
		@Inject(WidgetConstants.WIDGET_PARAMS) public params: IzendaReportWidgetParams,
		@Inject(WidgetConstants.WIDGET_OPTIONS) public widget: IWidgetOptions,
		private readonly _dashboardService: DashboardService,
		public readonly linkHelper: LinkHelperService
	) {
		this.reportId = params.reportId;
		this.reportName = params.reportName;
		this.widgetId = widget.id;
	}

	ngOnInit() {

	}

	removeFromDashboard() {
		this._dashboardService.removeWidget(this.widgetId);
	}

}
