import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RlHttpClient } from "../rl-http-client.service";
import { ITemplateEntityCountByStatus } from "./entity-status-counts.models";

@Injectable({ providedIn: "root" })
export class EntityStatusCountsService {
	constructor(private readonly _httpClient: RlHttpClient) { }

	public fetchStatusCounts(charTypeId: number, templateIDs: number[] = null): Observable<ITemplateEntityCountByStatus> {
		return this._httpClient
			.post(`/entity-status-counts/${charTypeId}`)
			.body(templateIDs ?? [])
			.fetch<ITemplateEntityCountByStatus>();
	}
}
