import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";
import { Observable, of } from "rxjs";
import { debounceTime, distinctUntilChanged, map, switchMap } from "rxjs/operators";
import { IAppOption } from "../profile-dropdown.models";

@Component({
	selector: "rl-select-div-control",
	templateUrl: "./select-div-control.component.html",
	styleUrls: ["./select-div-control.component.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SelectDivControlComponent),
			multi: true
		}
	],
})
export class SelectDivControlComponent implements OnInit, ControlValueAccessor {

	@Input()
	apps: IAppOption[];

	@Input()
	disabled = false;

	keyword: string;
	val: IAppOption;
	onChange: any = () => { };
	onTouched: any = () => { };

	get value() {
		return this.val;
	}

	set value(val) {
		this.val = val;
		this.onChange(val);
		this.onTouched();
	}

	writeValue(value: IAppOption): void {
		if (value) {
			this.val = value;
		}
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	constructor(
	) { }

	ngOnInit() {
	}

	typeAheadSearch = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			map(term => term.toLocaleLowerCase()),
			switchMap(lowerCaseTerm => {
				const filtered = this.apps.filter(app => {
					if (app.divId === +lowerCaseTerm) {
						return true;
					}
					const lowerCaseApp = app.name.toLocaleLowerCase();
					if (lowerCaseApp.indexOf(lowerCaseTerm) !== -1) {
						return true;
					}
					return false;
				});
				return of(filtered);
			}),
			map((data) => {
				return data;
			})
		)

	typeAheadSelected($event: NgbTypeaheadSelectItemEvent) {
		this.value = $event.item;
	}

	resultFormatter = (app: IAppOption) => {
		return `${app.name} (${app.divId})`;
	}

	inputFormatter = () => {
		return this.keyword || "";
	}
}
