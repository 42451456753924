import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { WidgetConstants } from "app/rightsline-app/components/dashboard/widget/widget.const";
import { IWidgetOptions } from "app/rightsline-app/components/dashboard/widget/widget.models";
import { GridDataSourceBuilder } from "rl-common/components/grid/datasource/builders/grid-datasource-builder";
import { GridViewColumnStrategy } from "rl-common/components/grid/datasource/columns/grid-view-column-strategy";
import { CommonGridDataSource } from "rl-common/components/grid/datasource/common-grid.datasource";
import { IGridFetchResults } from "rl-common/components/grid/datasource/grid-datasource";
import { GridOptions } from "rl-common/components/grid/grid.models";
import { SortDirection } from "rl-common/consts";
import { IEntitySearchDoc } from "rl-common/models";
import { ISavedSearch } from "rl-common/services/advanced-search/advanced-search.models";
import { AdvancedSearchService } from "rl-common/services/advanced-search/advanced-search.service";
import { DashboardService } from "rl-common/services/dashboard/dashboard.service";
import { IModsLinks, LinkHelperService } from "rl-common/services/link-helper.service";
import { SearchService } from "rl-common/services/search/search.service";
import { CharTypeIdUtil } from "rl-common/utils/char-type-id.util";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";

@Component({
	selector: "rl-saved-search-widget",
	templateUrl: "./saved-search-widget.component.html",
	styleUrls: ["./saved-search-widget.component.scss"]
})
export class SavedSearchWidgetComponent implements OnInit, OnDestroy {

	charTypeDisplayName: string;
	charTypeClass: string;
	searchFailed = false;
	modLinkHelper: IModsLinks;
	dataSource: CommonGridDataSource<IEntitySearchDoc>;

	gridOptions: GridOptions<IEntitySearchDoc> = {
		defaultGetCellDataFn: (column, doc) => {
			switch (column.key) {
				case "id":
					return doc.recordID;
				default:
					return doc.characteristics[column.key];
			}
		}
	};

	_subs: Subscription[] = [];

	constructor(
		@Inject(WidgetConstants.WIDGET_PARAMS) public savedSearch: ISavedSearch,
		@Inject(WidgetConstants.WIDGET_OPTIONS) public widgetOptions: IWidgetOptions,
		@Inject(WidgetConstants.WIDGET_INDEX) public widgetIndex: number,
		private readonly _advancedSearchService: AdvancedSearchService,
		private readonly _searchService: SearchService,
		public readonly linkHelper: LinkHelperService,
		private readonly _dashboardService: DashboardService,
		private readonly _gridDataSourceBuilder: GridDataSourceBuilder) {

		this.dataSource = this._gridDataSourceBuilder.commonGridDataSource<IEntitySearchDoc>(row => row.recordID)
			.setSorting({sortKey: "status_updated", sortDir: SortDirection.Descending})
			.withProgress(true)
			.withFetchFn((ds) => {
				const start = ds.rowOffset$.value;
				const pageSize = ds.pageSize$.value;
				const gridViewStrat = ds.columnStrategy as GridViewColumnStrategy<IEntitySearchDoc>;
				const gridView = gridViewStrat.gridView$.value;
				const sortKey = ds.sortKey$.value;
				const sortDir = ds.sortDir$.value;
				return this._searchService.search(this.savedSearch.charTypeId, null, this.savedSearch.query, { start, rows: pageSize, gridViewColumns: gridView.columns, sortField: sortKey.toString(), sortDir: sortDir })
					.pipe(
						map((results) => ({
							rowCount: results.numFound,
							rowData: results.documents,
							extraGridColumnResults: results.extraGridColumnResults
						} as IGridFetchResults<IEntitySearchDoc>))
					);
			})
			.withColumnStrategy(this._gridDataSourceBuilder.columnStrategies.searchDocColumnStrategy<IEntitySearchDoc>(this.savedSearch.charTypeId));
	}

	ngOnInit() {
		const cssClass = CharTypeIdUtil.toCssClass(this.savedSearch.charTypeId);
		this.charTypeClass = `far ${cssClass}`;
		this.charTypeDisplayName = CharTypeIdUtil.toDisplayName(this.savedSearch.charTypeId);
		this.modLinkHelper = this.linkHelper.mods[this.savedSearch.charTypeId];

		const sub = this.dataSource.fetchRows().subscribe();
		this._subs.push(sub);
	}

	ngOnDestroy() {
		this._subs.forEach(s => s.unsubscribe());
	}

	removeSearch() {
		this._advancedSearchService.removeSearchFromDashboard(this.savedSearch.id).subscribe(() => {
			this._dashboardService.removeWidget(this.widgetOptions.id);
		});
	}
}
