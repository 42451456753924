<div *ngIf="!isEntityRefresh" class="refresh-prompt shadow p-2 rounded">
	<p class="text-center m-2">
		<b>Rightsline just got an update!</b> Refresh your page to get the latest.<br>
		<button class="btn btn-sm btn-link" (click)="remindMeLater()">
			Remind me later
		</button>
		|
		<button class="btn btn-sm btn-link" (click)="refresh()">
			Refresh now
		</button>
		|
		<button *ngIf="browserSupportsBroadcasting" class="btn btn-sm btn-link" (click)="refreshAll()">
			Refresh all Rightsline tabs
		</button>
	</p>
</div>

<div *ngIf="isEntityRefresh" class="refresh-prompt shadow p-2 rounded">
	<p class="text-center m-2">
		<b>The Status of this record has changed.</b> Refresh your page to get the latest.<br>
		<button class="btn btn-sm btn-link" (click)="remindMeLater()">
			Remind me later
		</button>
		|
		<button class="btn btn-sm btn-link" (click)="refresh()">
			Refresh now
		</button>
	</p>
</div>