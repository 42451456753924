<div class="p-3">
	<div #wrapper>
		<rl-custom-views [charTypeId]="charTypeID" [gridViewColumnStrategy]="dataSource.columnStrategy">
		</rl-custom-views>
		<rl-grid #grid [dataSource]="dataSource" [options]="gridOptions"
			[layoutOptions]="{showFooter: true, height:'stretch'}">
			<ng-container *rlGridRowHoverOps="let record='rowData'">
				<button class="btn btn-sm btn-light" [title]="'edit ' + record.title">
					<i class="far fa-edit"></i></button>
				<button class="btn btn-sm btn-light text-danger" [title]="'delete ' + record.title">
					<i class="far fa-trash-alt"></i></button>
			</ng-container>
		</rl-grid>
	</div>
	<button class="btn btn-link" #handle style="float:right;cursor: ns-resize;" role="button" aria-label="Handle">
		<i class="far fa-ellipsis-h"></i>
	</button>
</div>
<div class="p-3">
	<div class="row">
		<div class="col bg-white">
			<b>chips</b>:
			<rl-chips-bitmap [charTypeId]="charTypeID"
				[bitmap]="(dataSelectStrategy.selectStateBitmapChange$ |async)?.selectedIdsBitmap"
				(removeItem)="dataSelectStrategy.deselectRowById($event, $event)"></rl-chips-bitmap>
		</div>
	</div>
</div>
<div class="p-3">
	<div class="row">
		<div class="col-6">
			<div class="card">
				<div class="card-header">Selected Data</div>
				<div class="card-body">
					<pre><code>{{selectState$ | async |json}}</code></pre>
				</div>
			</div>
			<div class="card">
				<div class="card-header">Errors</div>
				<div class="card-body">
					<pre><code>{{rowErrors | json}}</code></pre>

				</div>
			</div>
		</div>
		<div class="col-6">
			<div class="card">
				<div class="card-header">Selected Bitmap</div>
				<div class="card-body">
					<pre><code>Length: {{bitmapSerialized?.length}} bytes for {{(selectState$ | async)?.selectedIds?.length}} ids</code></pre>
					<pre><code style="white-space: pre-wrap" [innerText]="bitmapSerialized"></code></pre>
					<!-- <pre><code>{{ (dataSource.modifiedRowDatas$ | async).values() | iterToArray | json}}</code></pre> -->
				</div>
			</div>
			<div class="card">
				<div class="card-header">Grid Stuff</div>
				<div class="card-body">
					<button type="button" class="btn btn-primary" (click)="grid.toggleFullScreen()">Toggle
						Fullscreen</button>
					<button type="button" class="btn btn-primary" (click)="toggleColumnEdit()">Toggle
						Column Edit</button>
				</div>
			</div>
		</div>
	</div>

</div>