<div class="mx-3">
	<div class="dashboard-panel-head d-flex justify-content-between align-items-center">
		<div>
			<span class="title--overline">Saved Search</span>
			<div class="dashboard-panel-title d-flex align-items-center">
				<i class="fa-lg text-grey me-2" [ngClass]="[charTypeClass]" [title]="charTypeDisplayName"></i>
				<h3 class="m-0">{{ savedSearch.name }}</h3>
			</div>
		</div>
		<div>
			<rl-custom-views [charTypeId]="savedSearch.charTypeId" [gridViewColumnStrategy]="dataSource.columnStrategy">
			</rl-custom-views>
			<button class="btn btn-remove ms-2" (click)="removeSearch()" title="Remove Search from Dashboard">
				<i class="far fa-times"></i>
			</button>
		</div>
	</div>
	<div *ngIf="dataSource">
		<div *ngIf="searchFailed">
			A problem was encountered during your search ):
		</div>
		<rl-grid *ngIf="dataSource.rowData$.value?.length > 0" [options]="gridOptions" [dataSource]="dataSource"
			[layoutOptions]="{height: 290}" data-cy="SavedSearchRLGrid">
		</rl-grid>
		<div *ngIf="dataSource.rowData$.value?.length === 0 && !searchFailed">
			<div class="d-flex justify-content-center align-items-center no-record mt-3">
				<i class="far fa-search"></i>
				<h5 class="mb-0 ms-3">No Saved Searches found.</h5>
			</div>
		</div>
	</div>
</div>