<div class="notifications-widget menu-pane">
	<div class="d-flex flex-column">
		<div class="d-flex justify-content-between align-items-center menu-pane__header">
			<h5 class="flex-grow-1 m-0">Notifications</h5>
			<a class="btn btn-sm btn-link" [routerLink]="['/account/notifications']">View All</a>
		</div>
		<div class="menu-pane__body">
			<div class="px-3">
				<ng-template #empty>
					<div class="menu-pane__item mb-2">
						<em>No new Notifications.</em>
					</div>
				</ng-template>

				<div *ngIf="(notifications$ | async)?.length > 0; else empty">
					<div class="d-flex justify-content-between py-1"
						*ngFor="let notification of notifications$ | async;last as isLast"
						[class.border-bottom]="!isLast">
						<div class="flex-grow-1 pe-3">
							<span class="text-small">{{notification.createdAt | localDate:
								DateTimeFormatType.ShortDateOrTodayDateTime }}</span><br>
							<a [routerLink]="linkHelper.go(notification.charTypeId, notification.recordId)"
								target="_blank" (click)="markAsRead(notification)">{{notification.subject}}</a>
						</div>
						<div class="py-2">
							<button class="btn btn-icon btn-remove btn-sm" (click)="delete(notification)"
								title="Delete Notification"><i class="far fa-trash-alt"></i></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>