import { Component, Input, OnInit } from "@angular/core";
import { AboutAlliantService, AlliantSystemInformation } from "../../../../common/services/alliant/about-alliant.service";


@Component({
	selector: "rl-about-alliant",
	templateUrl: "./about-alliant.component.html",
	styleUrls: ["./about-alliant.component.scss"]
})
export class AboutAlliantComponent implements OnInit {

	@Input() loadingTemplate;
	loadingAlliantInformation = false;
	resettingMetadata = false;

	aboutAlliant: AlliantSystemInformation;

	constructor(private _aboutAlliantService: AboutAlliantService) { }

	async ngOnInit(): Promise<void> {
		this.loadingAlliantInformation = true;
		this.aboutAlliant = await this.getData();
		this.loadingAlliantInformation = false;
	}

	async getData(): Promise<AlliantSystemInformation> {
		return this._aboutAlliantService.getAlliantSystemInfo().toPromise();
	}

	async resetAlliantMetadata() {
		this.resettingMetadata = true;
		await this._aboutAlliantService.resetMetadata().toPromise();
		this.resettingMetadata = false;
	}
}

