import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { BehaviorSubject } from "rxjs";
import { filter, map } from "rxjs/operators";
import { SessionService } from "../session.service";
import { StorageUtil } from "../storage.service";
import { IAppOption } from "./../../../rightsline-app/components/header/profile-dropdown/profile-dropdown.models";

@Injectable({ providedIn: "root" })
export class RecentDivsService {

	public recentDivs$ = new BehaviorSubject<IAppOption[]>([]);
	public get recentDivs() {
		return this.recentDivs$.value;
	}

	private get recentDivsKey() {
		const userId = this._session.userId;
		return `recent-divisions-${userId}-${environment.apiClientID}`;
	}

	constructor(private readonly _session: SessionService) {

		this.refreshRecentDivs();

		_session.userId$
			.pipe(
				filter(userId => userId !== -1 && this._session.isValid)
			)
			.subscribe(() => {
				this.refreshRecentDivs();
			});

		_session.divId$
			.pipe(
				filter(divId => divId !== -1 && this._session.isValid),
				map(divId => {
					const chosenDiv = this._session.userApps.find(x => x.divId === divId);
					return chosenDiv;
				})
			)
			.subscribe(recentDiv => {
				this.addDivToRecents(recentDiv);
			});
	}

	public refreshRecentDivs() {
		const recentDivs = StorageUtil.get<IAppOption[]>(this.recentDivsKey) || [];
		this.recentDivs$.next(recentDivs);
	}

	private addDivToRecents(recentDiv: IAppOption) {
		const recentDivs = [recentDiv].concat(this.recentDivs.filter(x => x.divId !== recentDiv.divId));
		StorageUtil.set(this.recentDivsKey, recentDivs);
		this.refreshRecentDivs();
	}

}
