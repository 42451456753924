<div class="mx-3">
	<div class="dashboard-panel-head d-flex align-items-center">
		<h3 class="m-0">Quick Links</h3>
	</div>
	<div class="py-2" *ngIf="(links$ | async)?.length == 0">
		<em>No links saved.</em>
	</div>
	<div *ngFor="let link of links$ | async; let isLast = last">
		<div class="d-flex justify-content-between py-1 ps-2" [class.border-bottom]="!isLast">
			<div class="flex-grow-1 pe-3 pt-1">
				<a [routerLink]="link.url" [title]="link.title">{{link.title}}</a>
			</div>
			<button class="btn btn-sm btn-icon btn-remove" (click)="removeLink(link)" title="Delete Quick Link"
				[disabled]="deleted.has(link.id)">
				<i class="far fa-trash-alt"></i>
			</button>
		</div>
	</div>
</div>