<div class="search-results menu-pane" (mousedown)="stopMouseDownDefault($event)" [@slideUp]="animationState">
	<div class="d-flex flex-column">
		<div class="d-flex justify-content-between align-items-center menu-pane__header">
			<h5 class="flex-grow-1 m-0">
				Search
			</h5>
		</div>
		<div class="menu-pane__body">
			<div class="p-2 bg-white" *ngIf="noRecordsFound">
				<em>No Records found</em>
			</div>
			<div *ngFor="let charTypeId of charTypes">
				<div class="container border-bottom">
					<div class="row py-1 bg-light">
						<div class="col-9">
							<span class="me-2 char-type-icon">
								<i class="far {{toCssClass(charTypeId)}}"></i>
							</span>
							<span class="text-uppercase">
								{{ charTypeId | pluralCharTypeName }}
							</span>
							<span *ngIf="searchResults.results[charTypeId].numFound > groupLimit"
								class="match-count text-muted">
								({{searchResults.results[charTypeId].documents.length}} of
								{{searchResults.results[charTypeId].numFound}} results)
							</span>
						</div>
						<div class="col-3 text-end">
							<a [routerLink]="linkHelper.mods[charTypeId.toString()].list()"
								[queryParams]="{ keywords: searchTerm}">
								View All
							</a>
						</div>
					</div>
				</div>
				<div class="list-group list-group-flush list-group--menu">
					<div *ngFor="let doc of searchResults.results[charTypeId].documents" class="border-bottom">
						<a class="list-group-item list-group-item-action go-to-entity py-1" [title]="doc.title"
							[routerLink]="linkHelper.go(doc.charTypeID, doc.recordID)">
							<div class="row align-items-center g-0">
								<div class="col-3">
									<b>{{doc.recordID}}</b>
								</div>
								<div class="col-9 record-title">
									{{doc.title}}
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>