import { Directive } from "@angular/core";

@Directive({
	selector: "widget-handle"
})
export class WidgetHandleDirective {

	constructor() { }

}
