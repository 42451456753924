import { animate, state, style, transition, trigger, useAnimation } from '@angular/animations';
import { AfterViewInit, Component, Input } from '@angular/core';
import { isEmpty } from 'lodash';
import { CharTypeId } from 'rl-common/consts';
import { LinkHelperService } from 'rl-common/services/link-helper.service';
import { CharTypeIdUtil } from 'rl-common/utils/char-type-id.util';
import { animationTransitionTransform } from '../../../../common/components/animations/animations';
import { GROUP_LIMIT, ISearchResults } from '../universal-search/universal-search.models';

@Component({
	selector: 'rl-universal-search-results',
	templateUrl: './universal-search-results.component.html',
	styleUrls: ['./universal-search-results.component.scss'],
	animations: [
		trigger('slideUp', [
			transition(':enter', [
				useAnimation(animationTransitionTransform, {
					params: {
						opacityStart: 0,
						opacityEnd: 1,
						transformStart: 'translate3d(0px, -5px, 0px)',
						transformEnd: 'translate3d(0px, 0px, 0px)',
						time: '150ms ease-out'
					}
				})
			])
		])
	]
})
export class UniversalSearchResultsComponent implements AfterViewInit {

	animationState: "hidden" | "visible" = "hidden";

	groupLimit = GROUP_LIMIT;

	@Input()
	set searchResults(searchResults: ISearchResults) {
		this._searchResults = searchResults;
		this.charTypes = this._allCharTypes.filter(x => x in searchResults.results && searchResults.results[x]?.documents?.length > 0);
	}

	get searchResults() {
		return this._searchResults;
	}

	private _searchResults: ISearchResults;

	private readonly _allCharTypes: CharTypeId[] = [
		CharTypeId.User,
		CharTypeId.Project,
		CharTypeId.Property,
		CharTypeId.Transaction,
		CharTypeId.Inventory,
		CharTypeId.Job,
		CharTypeId.Invoice
	];

	charTypes: CharTypeId[] = [];

	get searchTerm() {
		return this._searchResults.term;
	}

	@Input()
	isLoading = false;

	get noRecordsFound() {
		return isEmpty(this.searchResults.results);
	}

	constructor(
		public readonly linkHelper: LinkHelperService
	) { }

	ngAfterViewInit(): void {
		setTimeout(() => { this.animationState = "visible" }, 0);
	}

	toCssClass(charTypeId: CharTypeId) {
		return CharTypeIdUtil.toCssClass(charTypeId);
	}

	stopMouseDownDefault(event: Event) {
		event.preventDefault(); // prevents clicks within the menu from removing focus on the search field
	}

}
