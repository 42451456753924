import { Component } from "@angular/core";
import { ModalService } from "rl-common/services/modal.service";


@Component({
	templateUrl: "./reset-password.component.html",
	styleUrls: ["./reset-password.component.scss"]
})
export class ResetPasswordComponent {
	constructor(
		private readonly _modalService: ModalService
	) { }

	showGdpr() {
		this._modalService.showGdpr();
	}
}

