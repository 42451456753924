<div class="card mb-5" [@stepEnter]="'slideIn'">
	<div class="card-header">
		<h1 class="text-center">{{stepTitle}}</h1>
	</div>
	<div class="card-body login-step">
		<ngb-alert type="danger" *ngIf="errorMessage?.length > 0 && !errorDismissed" (close)="errorDismissed = true"
			[dismissible]="true">
			<span [innerText]="errorMessage"></span>
		</ngb-alert>
		<div>
			<ng-content></ng-content>
		</div>
		<div *ngIf="showAltRegions" class="mt-3 border-top pt-2">
			<strong class="d-block py-1">Please select your region to continue to the Help Center:</strong>
			<div class="list-group-flush">
				<button class="list-group-item btn btn-sm btn-link w-100" (click)="altRegionSelect.emit('EU')"
					[innerText]="euRegionHostname"></button>
				<button class="list-group-item btn btn-sm btn-link w-100" (click)="altRegionSelect.emit('AU')"
					[innerText]="auRegionHostname"></button>
			</div>
		</div>
	</div>
	<div class="card-footer text-center">
		<button class="btn btn-sm btn-link" (click)="showGdpr()">GDPR &amp; PII Information</button> |
		<a class="btn btn-sm btn-link" href="https://www.rightsline.com/privacy-policy/" target="_blank"
			rel="noopener">Privacy Policy</a> |
		<a class="btn btn-sm btn-link" href="https://www.rightsline.com/terms-conditions/" target="_blank"
			rel="noopener">Terms &amp; Conditions</a>
	</div>
</div>