import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ProgressService } from "rl-common/services/progress.service";
import { Subscription, fromEvent } from "rxjs";
import { filter, take, tap } from "rxjs/operators";

@Component({
	selector: "rl-progress-bar",
	templateUrl: "./progress-bar.component.html",
	styleUrls: ["./progress-bar.component.scss"]
})
export class ProgressBarComponent implements OnInit, OnDestroy {

	@ViewChild("bar")
	bar: ElementRef<HTMLElement>;

	private _status: number;
	private _lastTimeOut: NodeJS.Timeout;

	private _subs: Subscription[] = [];

	constructor(
		private readonly ngZone: NgZone,
		private readonly _progressService: ProgressService
	) {

	}

	ngOnInit(): void {
		const sub = this._progressService.progressStarted
			.pipe(tap(() => this.start()))
			.subscribe();

		const sub2 = this._progressService.progressEnded
			.pipe(tap(() => this.done()))
			.subscribe();

		this._subs.push(sub, sub2);
	}

	ngOnDestroy() {
		this._subs.forEach(s => s.unsubscribe());
	}

	private start(): void {
		this.ngZone.runOutsideAngular(() => {
			this.setPosition(0);
			this.showBar();

			const work = () => {
				if (this._status != null) {
					this.inc();
					this._lastTimeOut = setTimeout(() => work(), 1000);
				} else {
					this._lastTimeOut = null;
				}
			};

			work();
		});
	}

	private inc(amount?: number): void {
		let n = this._status;
		if (n == null) {
			this.start();
		} else if (n > 1) {
			return;
		} else {
			if (typeof amount !== "number") {
				if (n >= 0 && n < 0.2) {
					amount = 0.1;
				} else if (n >= 0.2 && n < 0.5) {
					amount = 0.04;
				} else if (n >= 0.5 && n < 0.8) {
					amount = 0.02;
				} else if (n >= 0.8 && n < 0.99) {
					amount = 0.005;
				} else {
					amount = 0;
				}
			}

			n = this.clamp(n + amount, 0, 0.994);
			this.setPosition(n);
		}
	}

	private setPosition(n: number) {
		this._status = n;
		const perc = (-1 + n) * 100;
		if (this.bar) {
			this.bar.nativeElement.style.transform = `translate3d(${perc}%,0,0)`;
		}
	}

	private showBar() {
		if (this.bar?.nativeElement) {
			const el = this.bar.nativeElement;
			el.style.transform = `translate3d(-100%,0,0)`;
			el.style.opacity = "1";
		}
	}

	private hideBar() {
		if (this.bar?.nativeElement) {
			const el = this.bar.nativeElement;
			const sub = fromEvent(el, "transitionend")
				.pipe(
					filter(($event: TransitionEvent) => $event.propertyName === "transform"),
					take(1),
					tap(() => {
						el.style.opacity = "0";
						el.style.transform = `translate3d(-100%,0,0)`;
					})
				)
				.subscribe();
			this._subs.push(sub);
			el.style.transform = `translate3d(0%,0,0)`;
		}
	}

	private clamp(n: number, min: number, max: number): number {
		if (n < min) {
 return min;
}
		if (n > max) {
 return max;
}
		return n;
	}

	private done(): void {
		const el = this.bar.nativeElement;
		if (this._lastTimeOut) {
			clearTimeout(this._lastTimeOut);
		}

		const sub = fromEvent(el, "transitionend")
			.pipe(
				filter(($event: TransitionEvent) => $event.propertyName === "transform"),
				take(1),
				tap(() => this.hideBar())
			)
			.subscribe();
		this._subs.push(sub);
		this.inc(0.3 + 0.5 * Math.random());
		this._status = null;
	}

}
