<div class="wrapper position-relative" [ngClass]="{'is-logged-out': !(isLoggedIn$ | async)}">
	<rl-header class="head-main d-flex justify-content-between" role="banner"></rl-header>
	<rl-side-navigation class="nav-main d-flex flex-column justify-content-between position-relative"
		role="navigation"></rl-side-navigation>
	<article [ngClass]="{'d-flex flex-column justify-content-between': (showFooter && !isReporting)}" class="content"
		#content cdkScrollable role="main">
		<!-- need this span for footer placement -->
		<span>
			<router-outlet></router-outlet>
		</span>

		<rl-footer *ngIf="showFooter" class="footer-main" role="contentinfo"></rl-footer>
	</article>
</div>
<rl-progress-bar></rl-progress-bar>
<rl-growl-notifications></rl-growl-notifications>