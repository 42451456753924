import { animate, state, style, transition, trigger, useAnimation } from "@angular/animations";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { usDomainRe } from "rl-common/consts";
import { ComponentChanges } from "rl-common/models";
import { ModalService } from "rl-common/services/modal.service";
import { animationTransitionTransform } from "../../../../common/components/animations/animations";

@Component({
	selector: "rl-login-step",
	templateUrl: "./login-step.component.html",
	styleUrls: ["./login-step.component.scss"],
	animations: [
		trigger('stepEnter', [
			transition('void => slideIn', [
				useAnimation(animationTransitionTransform, {
					params: {
						opacityStart: 0,
						opacityEnd: 1,
						transformStart: 'translateX(20px)',
						transformEnd: 'translateX(0)',
						time: '250ms ease-out'
					}
				})
			])
		])
	]
})
export class LoginStepComponent implements OnInit, OnChanges {

	@Input()
	stepTitle: string;

	@Input()
	errorMessage: string;

	@Input()
	showAltRegions = false;

	@Output()
	altRegionSelect = new EventEmitter<string>();

	euRegionHostname: string;
	auRegionHostname: string;

	public errorDismissed: boolean;

	constructor(
		private readonly _modalService: ModalService) {
	}

	ngOnInit(): void {
		const currentHostname = new URL(window.location.href).hostname;
		const isUSDomain = usDomainRe.test(currentHostname);
		if (isUSDomain) {
			this.euRegionHostname = currentHostname.replace(usDomainRe, "$1.eu");
			this.auRegionHostname = currentHostname.replace(usDomainRe, "$1.com.au");
		}
	}

	ngOnChanges(changes: ComponentChanges<LoginStepComponent>) {
		if (changes.errorMessage && changes.errorMessage.previousValue !== changes.errorMessage.currentValue) {
			this.errorDismissed = false;
		}
	}

	showGdpr() {
		this._modalService.showGdpr();
	}

}
