<div *ngIf="!loadingAlliantInformation;else loadingTemplate" class="card">
	<div class="card-body">
		<h5 class="card-title">About Alliant Data Bridge</h5>
		<div *ngIf="!aboutAlliant" class="alert alert-warning" role="alert">
			Unable to retrieve information.
		</div>
		<table class="table table-striped m-0" *ngIf="aboutAlliant">
			<tbody>
				<tr>
					<th>Data Bridge Active?</th>
					<td>{{aboutAlliant.isDataBridgeActive}}</td>
				</tr>
				<tr>
					<th>Database</th>
					<td>{{aboutAlliant.serverAddress}}</td>
				</tr>
				<tr>
					<th>Application Layer</th>
					<td>{{aboutAlliant.layerDescription}}</td>
				</tr>
				<tr>
					<th>API Endpoint</th>
					<td>{{aboutAlliant.apiEndpoint}}</td>
				</tr>
				<tr>
					<th>Database Version</th>
					<td>{{aboutAlliant.currentDatabaseVersion}}</td>
				</tr>
				<tr>
					<th>Build Version</th>
					<td>{{aboutAlliant.softwareBuildNumber}}</td>
				</tr>
				<tr>
					<th>Release</th>
					<td>{{aboutAlliant.releaseName}}</td>
				</tr>
			</tbody>
		</table>
		<br /><button class="btn btn-sm btn-primary ms-2" (click)="resetAlliantMetadata()">
			<i *ngIf="resettingMetadata" class="far fa-spinner fa-spin me-2"></i>Reset Alliant Metadata
		</button>
	</div>
</div>