<div ngbDropdown placement="bottom-right" autoClose="outside">
	<button class="btn btn-link hide-caret" id="profileDropdown" ngbDropdownToggle role="button"
		data-cy="ProfileDropdownButton" aria-label="Expand Profile Menu">
		<rl-profile-picture [userName]="userName$ | async" [avatar]="profileAvatar$ | async">
		</rl-profile-picture>
	</button>
	<div ngbDropdownMenu aria-labelledby="profileDropdown" class="profile-dropdown-menu m-0 p-0"
		data-cy="ProfileDropdownDiv">
		<div class="d-flex align-items-center px-3 py-2">
			<h5 class="flex-grow-1 m-0">{{ displayName$ | async }}</h5>
			<div>
				<button class="btn btn-sm btn-link text-nowrap pe-0" (click)="logout()" data-cy="LogoutButton">
					<i class="far fa-power-off me-2"></i> Logout
				</button>
			</div>
		</div>
		<div class="p-3 bg-white profile-links">
			<div class="d-flex justify-content-between align-items-center">
				<span class="list-group-item" style="border:0;">
					<a routerLink="/account" class="profile-link d-flex align-items-center" data-cy="MyAccountLink">
						<i class="far fa-user me-2"></i>
						My Account
					</a>
				</span>
				<span class="text-muted text-uppercase text-tiny px-2">
					User ID: <strong>{{ userId$ | async }}</strong>
				</span>
				<span class="user-menu-avatar">
					<rl-upload-profile-picture size="xs"></rl-upload-profile-picture>
				</span>
			</div>
			<!-- TODO: Fill in the stubbed out routes below -->
			<ul class="list-group list-group-flush" *rlHasAnyReadAccess="[dataToolsAcl, companyAdminAcl, configAcl]">
				<li class="dropdown-divider"></li>
				<li *rlHasReadAccess="dataToolsAcl" class="list-group-item" data-cy="DataToolsLink">
					<a routerLink="/data-tools" class="profile-link d-flex align-items-center"> <i
							class="far fa-list me-2"></i>
						Data Tools
					</a>
				</li>
				<li *rlHasReadAccess="companyAdminAcl" class="list-group-item" data-cy="CompanyAdminLink">
					<a routerLink="/administration" class="profile-link d-flex align-items-center"><i
							class="far fa-wrench me-2"></i>
						Administration
					</a>
				</li>
				<li *rlHasReadAccess="configAcl" class="list-group-item" data-cy="ConfigurationLink">
					<rl-feature-flag [key]="'ux2Config'">
						<a on class="profile-link d-flex align-items-center" routerLink="/config">
							<i class="far fa-cog me-2"></i>
							Configuration
						</a>
						<a off rlBackofficeLink class="profile-link d-flex align-items-center"
							[classic]="'/configuration'" [phoenix]="['/config']">
							<i class="far fa-cog me-2"></i>
							Configuration
						</a>
					</rl-feature-flag>
				</li>
			</ul>
			<ul class="list-group list-group-flush">
				<li class="dropdown-divider"></li>
				<li class="list-group-item" data-cy="SupportPortalLink">
					<a [routerLink]="[]" role="button" (click)="supportPortal()"
						class="profile-link d-flex align-items-center">
						<i class="far fa-question-circle me-2"></i> Support
						<!-- needed?? <i class="far fa-sm fa-external-link ms-2"></i>-->
					</a>
				</li>
			</ul>
		</div>
		<div class="d-flex flex-column px-3 py-2" *ngIf="hasMultipleDivs$ | async">
			<div class="pb-2">
				<rl-select-div-control [apps]="availableDivs" [(ngModel)]="selectedApp" (change)="selectDiv()"
					[disabled]="isSwitching">
				</rl-select-div-control>
				<select class="form-select form-control-sm" [formControl]="divControl" *ngIf="!isSwitching"
					data-cy="SwitchDivSelect">
					<option *ngFor="let app of availableDivs" [ngValue]="app" [attr.data-cy]="'SwitchDiv_' + app.divId">
						{{app.name}} -- Div {{app.divId}}
					</option>
				</select>
				<select class="form-select form-control-sm" disabled="disabled" *ngIf="isSwitching">
					<option>
						Switching to {{ selectedApp?.name }}
					</option>
				</select>
			</div>
			<rl-recent-divs (onDivSelect)="switchToApp($event)" [disabled]="isSwitching"></rl-recent-divs>
		</div>
	</div>
</div>