<div class="p-3">
	<div #wrapper>
		<rl-grid #grid [options]="gridOptions" [dataSource]="dataSource">
			<ng-container *rlGridRowHoverOps="let rowData; let rowPath = rowPath">
				<button class="btn btn-sm btn-light" [title]="'edit ' + rowData.title">
					<i class="far fa-edit"></i></button>
				<button class="btn btn-sm btn-light text-danger" [title]="'delete ' + rowData.title">
					<i class="far fa-trash-alt"></i></button>
				<button class="btn btn-sm btn-primary" title="refresh nested" (click)="refreshNested(rowPath)"><i
						class="far fa-recycle"></i></button>
			</ng-container>
			<ng-container
				*rlGridNestedTemplate="let nestedDataSource;let rowPath = rowPath; let parentRowData = parentRowData">
				<strong>Rights</strong>
				<rl-grid [options]="nestedGridOptions" [dataSource]="nestedDataSource">
					<ng-container *rlGridRowHoverOps="let rowData">
						<button class="btn btn-sm btn-light" [title]="'edit ' + rowData.title">
							<i class="far fa-edit"></i></button>
						<button class="btn btn-sm btn-light text-danger" [title]="'delete ' + rowData.title">
							<i class="far fa-trash-alt"></i></button>
					</ng-container>
				</rl-grid>
			</ng-container>
		</rl-grid>
	</div>
	<button class="btn btn-link" #handle style="float:right;cursor: ns-resize;">
		<i class="far fa-ellipsis-h"></i>
	</button>
</div>
<div class="p-3">
	<div class="row">
		<div class="col">
			<div class="card">
				<div class="card-header">Selected Data</div>
				<div class="card-body">
					<pre><code>{{selectState$ | async | json}}</code></pre>
				</div>
			</div>
		</div>
		<div class="col">
			<div class="card">
				<div class="card-header">Grid Stuff</div>
				<div class="card-body">
					<button type="button" class="btn btn-primary" (click)="grid.toggleFullScreen()">Toggle
						Fullscreen</button>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="p-3" style="margin-bottom:200px;">
	<div class="rl-grid">
		<table class="rl-grid__header">
			<tr class="rl-grid__header__row" style="background-color: #eff4fd;">
				<th class="rl-grid__header__cell p-2">Catalog Items</th>
				<th class="rl-grid__header__cell p-2">Description</th>
				<th class="rl-grid__header__cell p-2">Term Start</th>
				<th class="rl-grid__header__cell p-2">Media</th>
			</tr>
			<tr class="rl-grid__header__row" style="background-color: #e7eefb;">
				<th class="rl-grid__header__cell p-2" style="vertical-align:bottom;">
					<input type="text" class="form-control" style="background-color: transparent;">
					<div class="d-block text-end">
						<button class="btn btn-sm btn-icon btn-success" disabled><i
								class="far fa-check fa-sm"></i></button>
						<button class="btn btn-sm btn-icon btn-remove" disabled><i
								class="far fa-times fa-sm"></i></button>
					</div>
				</th>
				<th class="rl-grid__header__cell p-2" style="vertical-align:bottom;">
					<textarea class="form-control" style="background-color: transparent;"></textarea>
					<div class="d-block text-end">
						<button class="btn btn-sm btn-icon btn-success" disabled><i
								class="far fa-check fa-sm"></i></button>
						<button class="btn btn-sm btn-icon btn-remove" disabled><i
								class="far fa-times fa-sm"></i></button>
					</div>
				</th>
				<!-- The below show fields that have data entered. The check and x become enabled, and clicking the check shows a popover with option(s).-->
				<th class="rl-grid__header__cell p-2" style="vertical-align:bottom;">
					<input type="text" class="form-control" value="04/21/2019" style="background-color: transparent;">
					<div class="d-block text-end">
						<button class="btn btn-sm btn-icon btn-success" title="Apply this value"
							[ngbPopover]="singlePopover" placement="bottom-right"><i class="far fa-check fa-sm"
								style="color:#3cac22"></i></button>
						<button class="btn btn-sm btn-icon btn-remove" title="Clear"><i
								class="far fa-times fa-sm"></i></button>
					</div>
				</th>
				<th class="rl-grid__header__cell p-2" style="vertical-align:bottom;">
					<select class="form-select" style="background-color: transparent;">
						<option>Multi-Select / LOV</option>
						<option>Value 1</option>
						<option>Value 2</option>
					</select>
					<div class="d-block text-end">
						<button class="btn btn-sm btn-icon btn-success" title="Apply this value"
							[ngbPopover]="multiPopover" placement="bottom-right"><i class="far fa-check fa-sm"
								style="color:#3cac22"></i></button>
						<button class="btn btn-sm btn-icon btn-remove" title="Clear"><i
								class="far fa-times fa-sm"></i></button>
					</div>
				</th>
			</tr>
		</table>
	</div>
</div>


<ng-template #singlePopover>
	<div class="py-2">
		Replace value on all rows
	</div>
	<div class="bg-light py-2 text-center">
		<button class="btn btn-sm btn-info">Apply to 8 records</button>
	</div>
</ng-template>

<ng-template #multiPopover>
	<div class="py-2">
		<div class="form-check" class="ps-0">
			<input type=radio id="replace" value="replace" checked>
			<label for="replace" class="ps-1">Replace value on all rows</label>
		</div>
		<div class="form-check" class="ps-0">
			<input type=radio id="add" value="add">
			<label for="add" class="ps-1">Add value to all rows</label>
		</div>
		<div class="form-check" class="ps-0">
			<input type=radio id="remove" value="remove">
			<label for="remove" class="ps-1">Remove value from all rows</label>
		</div>
	</div>
	<div class="bg-light py-2 text-center">
		<button class="btn btn-sm btn-info">Apply to 8 records</button>
	</div>
</ng-template>