import { Injectable } from "@angular/core";
import { RlHttpClient } from "../rl-http-client.service";
import { IExecuteJobResponse } from "../workflow/workflow.messages";

@Injectable({
	providedIn: "root"
})
export class DevToolsService {
	private _baseUrl = "/dev-tools";

	constructor(
		private readonly _httpClient: RlHttpClient,
	) { }

	throwException(responseCode: number) {
		return this._httpClient
			.get(`${this._baseUrl}/test-exception`)
			.params({
				responseCode
			}).fetch();
	}

	processAlertNotifications() {
		return this._httpClient
			.post(`${this._baseUrl}/process-alert-notifications`)
			.fetch<IExecuteJobResponse>();
	}

	runExchangeRateJob(dealId: number) {
		return this._httpClient
			.post(`${this._baseUrl}/run-exchange-rate-job/${dealId}`)
			.fetch<void>();
	}

}
