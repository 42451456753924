import { transition, trigger, useAnimation } from "@angular/animations";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { QuickLinksService } from "rl-common/services/quick-links/quick-links.service";
import { combineLatest, Observable, Subscription } from "rxjs";
import { filter, map } from "rxjs/operators";
import { animationTransitionOpacity } from "../../../../common/components/animations/animations";
import { IFavoriteLink } from "../../../../common/rl-common.models";


@Component({
	selector: "rl-header-favorites",
	templateUrl: "./header-favorites.component.html",
	styleUrls: ["./header-favorites.component.scss"],
	animations: [
		trigger('fadeIn', [
			transition(':enter', [
				useAnimation(animationTransitionOpacity, {
					params: {
						opacityStart: 0,
						opacityEnd: 1,
						time: '250ms ease-out'
					}
				})
			])
		])
	]
})
export class HeaderFavoritesComponent implements OnInit, OnDestroy {

	links$ = this._quickLinksService.links$;
	loading$ = this._quickLinksService.loadingLinks$;
	hasQuickLink$: Observable<boolean>;
	isAdding = false;
	deleted = this._quickLinksService.deleted;

	private _subscriptions: Subscription[] = [];

	constructor(
		private readonly _quickLinksService: QuickLinksService,
		private _router: Router
	) { }

	ngOnInit() {
		const navigationEnd$ = this._router.events.pipe(filter(x => x instanceof NavigationEnd));
		this.hasQuickLink$ = combineLatest([this.links$, navigationEnd$]).pipe(
			map(([links]) => {
				const currentUrl = this.getCurrentUrl();
				return links.map(x => x.url).includes(currentUrl);
			})
		);
	}

	ngOnDestroy() {
		this._subscriptions.forEach(sub => sub.unsubscribe());
	}

	public removeLink(link: IFavoriteLink) {
		const sub = this._quickLinksService.deleteQuickLinks(link.id).subscribe();
		this._subscriptions.push(sub);
	}

	private getCurrentUrl() {
		return this._router.url.split("#")[0];
	}

	public addToQuickLinks(): void {
		this.isAdding = true;
		const name = document.title;
		const currentUrl = this.getCurrentUrl();
		const sub = this._quickLinksService.saveQuickLink(name, currentUrl).subscribe(() => {
			this.isAdding = false;
		});
		this._subscriptions.push(sub);
	}

}
