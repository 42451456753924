<div>
    <table class="table">
        <caption>Database and Datamart Counts</caption>
        <thead class="thead-light">
            <tr>
                <th scope="col">Database</th>
                <th scope="col">CharType</th>
                <th scope="col">Row Count</th>
                <th scope="col">Last Updated</th>
                <th scope="col">Datamart</th>
                <th scope="col">Row Count</th>
                <th scope="col">Datamart Last Updated</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let count of counts" [class.table-warning]="count.risRowCount != count.datamartRowCount">
                <td>RIS</td>
                <td>{{count.tableName}}</td>
                <td>{{count.risRowCount}}</td>
                <td>{{(count.maxLastUpdated > count.maxStatusUpdated ? count.maxLastUpdated : count.maxStatusUpdated) | date:'fullTime'}}</td>
                <td>RDW_{{divId}}</td>
                <td>{{count.datamartRowCount}}</td>
                <td>{{(count.datamartMaxLastUpdated > count.datamartMaxStatusUpdated ? count.datamartMaxLastUpdated : count.datamartMaxStatusUpdated) | date:'fullTime'}}</td>
            </tr>
        </tbody>
    </table>
</div>
