import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { GridDataSourceBuilder } from "rl-common/components/grid/datasource/builders/grid-datasource-builder";
import { SearchGridDataSource } from "rl-common/components/grid/datasource/search-grid.datasource";
import { GridTableComponent } from "rl-common/components/grid/grid-table/grid-table.component";
import { GridColumn, GridOptions } from "rl-common/components/grid/grid.models";
import { IEntitySearchDoc, IQueryNode } from "rl-common/models";
import { IModsLinks, LinkHelperService } from "rl-common/services/link-helper.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";


@Component({
	selector: "rl-grid-test",
	templateUrl: "./grid-test.component.html"
})
export class GridTestComponent implements OnInit, OnDestroy {

	@ViewChild("wrapper", { static: true })
	wrapperElRef: ElementRef<HTMLElement>;

	@ViewChild("handle", { static: true })
	handleElRef: ElementRef<HTMLElement>;

	@ViewChild("grid", { static: true })
	grid: GridTableComponent;

	modLinkHelper: IModsLinks;

	charTypeID = 1;

	columns: GridColumn<IEntitySearchDoc>[] = [
		{
			key: "id",
			headerName: "ID",
			renderer: "link_id",
			width: "min-content",
			getCellData: (doc) => doc,
			locked: true
		},
		{
			key: "name",
			headerName: "Name",
			width: "max-content",
			renderer: "link_name",
			getCellData: (doc) => doc,
			showHierarchy: true
		},
		{
			key: "template",
			headerName: "Template",
			width: "auto",
			renderer: "text",
			getCellData: (doc) => doc.template
		},
		{
			key: "status",
			headerName: "Status",
			width: "auto",
			renderer: "text",
			getCellData: (doc) => doc.status
		}
	];
	gridOptions: GridOptions<IEntitySearchDoc> = {
		defaultGetCellDataFn: (column, doc) => {
			switch (column.key) {
				case "id":
					return doc.recordID;
				default:
					return doc.characteristics[column.key];
			}
		},
		// bodyHeight: "minmax(auto, 300px)",
	};

	dataSource: SearchGridDataSource<IEntitySearchDoc>;
	selectState$: Observable<any>;

	constructor(
		private linkHelper: LinkHelperService,
		private gridDataSourceBuilder: GridDataSourceBuilder
	) { }

	ngOnInit() {

		this.dataSource = this.gridDataSourceBuilder.entitySearchGridDataSourceDeprecated(this.charTypeID);
		const hierarchyStrategy = this.gridDataSourceBuilder.hierarchyStrategies
			.entitySearchDocHierarchyStrategy(this.charTypeID, this.charTypeID);

		const viewColumnStrategy = this.gridDataSourceBuilder.columnStrategies.searchDocColumnStrategy(this.charTypeID);

		this.dataSource
			// .setColumns(this.columns)
			.setSorting({ sortKey: "status_updated", sortDir: 0 })
			.setPaging({ pageSize: 25 })
			.withHierarchyStrategy(hierarchyStrategy)
			.withColumnStrategy(viewColumnStrategy);

		this.selectState$ = this.dataSource.dataSelectStrategy.selectStateChange$
			.pipe(
				map((state) => ({
					isAllSelected: state.isAllSelected,
					selectedIds: Array.from(state.selectedIds),
					selectedValues: Array.from(state.selectedValues),
					deselectedIds: Array.from(state.deselectedIds),
					deselectedValues: Array.from(state.deselectedValues)
				}))
			);

		this.modLinkHelper = this.linkHelper.mods[1];
		this.search();

		// this.ngZone.runOutsideAngular(() => {
		const wrapper = this.wrapperElRef.nativeElement;
		const handle = this.handleElRef.nativeElement;
		const documentEl = handle.ownerDocument;
		const mousemove = (event: MouseEvent) => {
			wrapper.style.height = wrapper.getBoundingClientRect().height + event.movementY + "px";
		};
		const mouseup = () => {
			documentEl.removeEventListener("mousemove", mousemove);
			documentEl.removeEventListener("mousemove", mouseup);
			this.grid.calculateLayout();
		};

		handle.addEventListener("mousedown", () => {
			documentEl.addEventListener("mousemove", mousemove);
			documentEl.addEventListener("mouseup", mouseup);
		});
		// });
	}

	search() {

		const query: IQueryNode = {};
		this.dataSource
			.setQueryNode(query)
			.fetchRows()
			.subscribe();
	}

	ngOnDestroy(): void {
		// Called once, before the instance is destroyed.
		// Add 'implements OnDestroy' to the class.
	}
}
