// TODO: APP-6058 | Remove this file when no longer supporting legacy URLs.
import { Routes, UrlSegment } from "@angular/router";

const lowercaseUrlSegment = (urlSegment: UrlSegment) => {
	urlSegment.path = urlSegment.path.toLowerCase();
	return urlSegment;
};

const matchPaths = (url: UrlSegment[], regExps: RegExp[]): boolean => {
	let match = true;
	url.forEach((urlSegment, index) => {
		if (lowercaseUrlSegment(urlSegment).path.match(regExps[index]) === null) {
			match = false;
		}
	});
	return match;
};

const restructureUrl = (url: UrlSegment[], newSegments: string[]): UrlSegment[] => {
	url.length = 0;
	newSegments.forEach(segment => {
		url.push(new UrlSegment(segment, {}));
	});
	return url;
};

export const CatalogMatcher = (url: UrlSegment[]) => {
	let returnConsumed = false;

	if (url.length === 1 && matchPaths(url, [/^asset(#.*)?$/gm])) {
		url = restructureUrl(url, ["mod1"]);
		returnConsumed = true;
	}

	if (url.length === 3 && matchPaths(url, [/^asset/gm, /^rights/gm, /^(\d+)/gm])) {
		url = restructureUrl(url, ["mod1", url[2].path, "rights"]);
		returnConsumed = true;
	}

	if (url.length === 3 && matchPaths(url, [/^asset/gm, /^usages/gm, /^(\d+)/gm])) {
		url = restructureUrl(url, ["mod1", url[2].path, "tables"]);
		returnConsumed = true;
	}

	if (url.length === 3 && matchPaths(url, [/^asset/gm, /^files/gm, /^(\d+)/gm])) {
		url = restructureUrl(url, ["mod1", url[2].path, "files"]);
		returnConsumed = true;
	}

	if (url.length === 3 && matchPaths(url, [/^asset/gm, /^(\w+)/gm, /^(\d+)/gm])) {
		url = restructureUrl(url, ["mod1", url[2].path]);
		returnConsumed = true;
	}

	return returnConsumed ? { consumed: url } : null as ({ consumed: UrlSegment[] });
};

export const ContactMatcher = (url: UrlSegment[]) => {
	let returnConsumed = false;

	if (url.length === 1 && matchPaths(url, [/^contact(#.*)?$/gm])) {
		url = restructureUrl(url, ["mod2"]);
		returnConsumed = true;
	}

	if (url.length === 3 && matchPaths(url, [/^contact/gm, /^deals/gm, /^(\d+)/gm])) {
		url = restructureUrl(url, ["mod2", url[2].path, "parent-deals"]);
		returnConsumed = true;
	}

	if (url.length === 3 && matchPaths(url, [/^contact/gm, /^financials/gm, /^(\d+)/gm])) {
		url = restructureUrl(url, ["mod2", url[2].path, "parent-accounting"]);
		returnConsumed = true;
	}

	if (url.length === 3 && matchPaths(url, [/^contact/gm, /^files/gm, /^(\d+)/gm])) {
		url = restructureUrl(url, ["mod2", url[2].path, "files"]);
		returnConsumed = true;
	}

	if (url.length === 3 && matchPaths(url, [/^contact/gm, /^(\w+)/gm, /^(\d+)/gm])) {
		url = restructureUrl(url, ["mod2", url[2].path]);
		returnConsumed = true;
	}

	return returnConsumed ? { consumed: url } : null as ({ consumed: UrlSegment[] });
};

export const DealMatcher = (url: UrlSegment[]) => {
	let returnConsumed = false;

	if (url.length === 1 && matchPaths(url, [/^contract(#.*)?$/gm])) {
		url = restructureUrl(url, ["mod4"]);
		returnConsumed = true;
	}

	if (url.length === 3 && matchPaths(url, [/^contract/gm, /^rights/gm, /^(\d+)/gm])) {
		url = restructureUrl(url, ["mod4", url[2].path, "rights"]);
		returnConsumed = true;
	}

	if (url.length === 3 && matchPaths(url, [/^contract/gm, /^royalties/gm, /^(\d+)/gm])) {
		url = restructureUrl(url, ["mod4", url[2].path, "royalties"]);
		returnConsumed = true;
	}

	if (url.length === 3 && matchPaths(url, [/^contract/gm, /^financials/gm, /^(\d+)/gm])) {
		url = restructureUrl(url, ["mod4", url[2].path, "accounting-documents"]);
		returnConsumed = true;
	}

	if (url.length === 3 && matchPaths(url, [/^contract/gm, /^documents/gm, /^(\d+)/gm])) {
		url = restructureUrl(url, ["mod4", url[2].path, "files"]);
		returnConsumed = true;
	}

	if (url.length === 3 && matchPaths(url, [/^contract/gm, /^(\w+)/gm, /^(\d+)/gm])) {
		url = restructureUrl(url, ["mod4", url[2].path]);
		returnConsumed = true;
	}

	return returnConsumed ? { consumed: url } : null as ({ consumed: UrlSegment[] });
};

export const InvoiceMatcher = (url: UrlSegment[]) => {
	let returnConsumed = false;

	if (url.length === 1 && matchPaths(url, [/^financial(#.*)?$/gm])) {
		url = restructureUrl(url, ["mod7"]);
		returnConsumed = true;
	}

	if (url.length === 3 && matchPaths(url, [/^financial/gm, /^(\w+)/gm, /^(\d+)/gm])) {
		url = restructureUrl(url, ["mod7", url[2].path]);
		returnConsumed = true;
	}

	return returnConsumed ? { consumed: url } : null as ({ consumed: UrlSegment[] });
};

export const JobMatcher = (url: UrlSegment[]) => {
	let returnConsumed = false;

	if (url.length === 1 && matchPaths(url, [/^jobs(#.*)?$/gm])) {
		url = restructureUrl(url, ["mod15"]);
		returnConsumed = true;
	}

	if (url.length === 3 && matchPaths(url, [/^jobs/gm, /^inventory/gm, /^(\d+)/gm])) {
		url = restructureUrl(url, ["mod15", url[2].path, "inventory"]);
		returnConsumed = true;
	}

	if (url.length === 3 && matchPaths(url, [/^jobs/gm, /^files/gm, /^(\d+)/gm])) {
		url = restructureUrl(url, ["mod15", url[2].path, "files"]);
		returnConsumed = true;
	}

	if (url.length === 3 && matchPaths(url, [/^jobs/gm, /^(\w+)/gm, /^(\d+)/gm])) {
		url = restructureUrl(url, ["mod15", url[2].path]);
		returnConsumed = true;
	}

	return returnConsumed ? { consumed: url } : null as ({ consumed: UrlSegment[] });
};

export const ProjectMatcher = (url: UrlSegment[]) => {
	let returnConsumed = false;

	if (url.length === 1 && matchPaths(url, [/^project(#.*)?$/gm])) {
		url = restructureUrl(url, ["mod16"]);
		returnConsumed = true;
	}

	if (url.length === 3 && matchPaths(url, [/^project/gm, /^files/gm, /^(\d+)/gm])) {
		url = restructureUrl(url, ["mod16", url[2].path, "files"]);
		returnConsumed = true;
	}

	if (url.length === 3 && matchPaths(url, [/^project/gm, /^(\w+)/gm, /^(\d+)/gm])) {
		url = restructureUrl(url, ["mod16", url[2].path]);
		returnConsumed = true;
	}

	return returnConsumed ? { consumed: url } : null as ({ consumed: UrlSegment[] });
};

export const InventoryMatcher = (url: UrlSegment[]) => {
	let returnConsumed = false;

	if (url.length === 1 && matchPaths(url, [/^inventory(#.*)?$/gm])) {
		url = restructureUrl(url, ["mod17"]);
		returnConsumed = true;
	}

	if (url.length === 3 && matchPaths(url, [/^inventory/gm, /^deals/gm, /^(\d+)/gm])) {
		url = restructureUrl(url, ["mod17", url[2].path, "parent-deals"]);
		returnConsumed = true;
	}

	if (url.length === 3 && matchPaths(url, [/^inventory/gm, /^files/gm, /^(\d+)/gm])) {
		url = restructureUrl(url, ["mod17", url[2].path, "files"]);
		returnConsumed = true;
	}

	if (url.length === 3 && matchPaths(url, [/^inventory/gm, /^media/gm, /^(\d+)/gm])) {
		url = restructureUrl(url, ["mod17", url[2].path, "media"]);
		returnConsumed = true;
	}

	if (url.length === 3 && matchPaths(url, [/^inventory/gm, /^(\w+)/gm, /^(\d+)/gm])) {
		url = restructureUrl(url, ["mod17", url[2].path]);
		returnConsumed = true;
	}

	return returnConsumed ? { consumed: url } : null as ({ consumed: UrlSegment[] });
};

export const WorkflowMatcher = (url: UrlSegment[]) => {
	let returnConsumed = false;

	if (url.length === 2 && matchPaths(url, [/^workflow/gm, /^execute(#.*)?$/gm])) {
		url = restructureUrl(url, ["workflow-execute"]);
		returnConsumed = true;
	}

	return returnConsumed ? { consumed: url } : null as ({ consumed: UrlSegment[] });
};

export const LoginMatcher = (url: UrlSegment[]) => {
	let returnConsumed = false;

	if (url.length === 2 && matchPaths(url, [/^account/gm, /^login(#.*)?$/gm])) {
		url = restructureUrl(url, ["login"]);
		returnConsumed = true;
	}

	return returnConsumed ? { consumed: url } : null as ({ consumed: UrlSegment[] });
};

export const LegacyRoutes: Routes = [
	{
		matcher: CatalogMatcher,
		redirectTo: "mod1"
	},
	{
		matcher: ContactMatcher,
		redirectTo: "mod2"
	},
	{
		matcher: DealMatcher,
		redirectTo: "mod4"
	},
	{
		matcher: InvoiceMatcher,
		redirectTo: "mod7"
	},
	{
		matcher: JobMatcher,
		redirectTo: "mod15"
	},
	{
		matcher: ProjectMatcher,
		redirectTo: "mod16"
	},
	{
		matcher: InventoryMatcher,
		redirectTo: "mod17"
	},
	{
		matcher: WorkflowMatcher,
		redirectTo: "workflow-execute"
	},
	{
		matcher: LoginMatcher,
		redirectTo: "login"
	},
];
