import { IEntitySearchDoc } from "rl-common/models";

export interface ISearchResults {
	term: string;
	results: { [charTypeId: number]: ISearchResultGroup };
}

export interface ISearchResultGroup {
	documents: IEntitySearchDoc[],
	numFound: number
}

export const EMPTY_SEARCH_RESULTS: ISearchResults = { term: "", results: {} };

export const GROUP_LIMIT = 5;