import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
	selector: "rl-app-home",
	templateUrl: "./home.component.html"
})
export class HomeComponent implements OnInit {
	constructor(private browserTitle: Title) { }

	ngOnInit() {
		this.browserTitle.setTitle("Rightsline");
	}
}
