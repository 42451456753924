import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { GridDataSourceBuilder } from "rl-common/components/grid/datasource/builders/grid-datasource-builder";
import { EntityCharDataGridDataSource } from "rl-common/components/grid/datasource/entity-chardata-grid.datasource";
import { GridTableComponent } from "rl-common/components/grid/grid-table/grid-table.component";
import { BulkEditsPackage, GridCellError, GridColumn, GridOptions } from "rl-common/components/grid/grid.models";
import { ICharacteristicData, IEntity, IEntityRecTemplateCharData, IQueryNode } from "rl-common/models";
import { IModsLinks, LinkHelperService } from "rl-common/services/link-helper.service";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { merge, Observable, of, Subscription } from "rxjs";
import { map } from "rxjs/operators";


@Component({
	selector: "rl-grid-test2",
	templateUrl: "./grid-test2.component.html"
})
export class GridTest2Component implements OnInit, OnDestroy {

	@ViewChild("wrapper", { static: true })
	wrapperElRef: ElementRef<HTMLElement>;

	@ViewChild("handle", { static: true })
	handleElRef: ElementRef<HTMLElement>;

	@ViewChild("grid", { static: true })
	grid: GridTableComponent<IEntityRecTemplateCharData>;

	modLinkHelper: IModsLinks;

	charTypeID = 1;

	columns: GridColumn<IEntityRecTemplateCharData>[] = [
		{
			key: "id",
			headerName: "ID",
			renderer: "link_id",
			width: "min-content",
			getCellData: (doc) => doc,
			locked: true,
			sortKey: "recordid"
		}
	];
	gridOptions: GridOptions<IEntityRecTemplateCharData> = {
		defaultGetCellDataFn: (column, tuple) => {
			switch (column.key) {
				case "id":
					return tuple.record.recordID;
				default:
					return "dummy data";
			}
		}		// bodyHeight: "minmax(auto, 300px)",
	};

	dataSource: EntityCharDataGridDataSource;

	selectState$: Observable<any>;

	bulkEditsPackage: BulkEditsPackage<ICharacteristicData[]>;

	isValid: boolean;
	rowErrors: GridCellError[];

	subs: Subscription[] = [];

	constructor(
		private linkHelper: LinkHelperService,
		private oneConfigService: OneConfigService,
		private gridDataSourceBuidler: GridDataSourceBuilder
	) {
	}

	ngOnInit() {
		this.modLinkHelper = this.linkHelper.mods[1];

		const wrapper = this.wrapperElRef.nativeElement;
		const handle = this.handleElRef.nativeElement;
		const documentEl = handle.ownerDocument;
		const mousemove = (event: MouseEvent) => {
			wrapper.style.height = wrapper.getBoundingClientRect().height + event.movementY + "px";
		};
		const mouseup = () => {
			documentEl.removeEventListener("mousemove", mousemove);
			documentEl.removeEventListener("mousemove", mouseup);
			this.grid.calculateLayout();
		};

		handle.addEventListener("mousedown", () => {
			documentEl.addEventListener("mousemove", mousemove);
			documentEl.addEventListener("mouseup", mouseup);
		});


		const dataChangeStrategy = this.gridDataSourceBuidler.dataChangeStrategies.entityCharDataDataChangeStrategy<IEntityRecTemplateCharData>(this.oneConfigService)
			.setEditEnabled(true)
			.setColumnEditToolsEnabled(true);

		const dataSelectStrategy = this.gridDataSourceBuidler.dataSelectStrategies.entityCharDataDataSelectStrategy<Number, IEntity>((rowData) => rowData.record.recordID, (rowData) => rowData.record)
			.setRequired(true);

		this.selectState$ = dataSelectStrategy.selectStateChange$.pipe(map((state) => ({
			isAllSelected: state.isAllSelected,
			selectedIds: Array.from(state.selectedIds),
			selectedValues: Array.from(state.selectedValues)
		})));

		const columnSelectStrategy = this.gridDataSourceBuidler.columnStrategies.componentEditCustomViewStrategy(this.charTypeID, null, null, null);

		this.dataSource = this.gridDataSourceBuidler.entityCharDataGridDataSource(this.charTypeID)
			.withProgress(true)
			.withDataChangeStrategy(dataChangeStrategy)
			.withDataSelectStrategy(dataSelectStrategy)
			.withColumnStrategy(columnSelectStrategy)
			.withDragDropAction((ds, $event) => {
				console.info($event);
				return of(null);
			})
			.setSorting({ sortDir: 0, sortKey: "status_updated" })
			.setPaging({ rowOffset: 0, pageSize: 25 });

		const sub1 = merge(this.dataSource.modifiedRowDatas$, this.dataSource.modifiedColumnDatas$)
			.subscribe(() => {
				this.bulkEditsPackage = dataChangeStrategy.makeCharDataBulkEditPackage(columnSelectStrategy.cmds);
			});

		const sub2 = this.dataSource.rowErrors$
			.subscribe((errors) => {
				setTimeout(() => {
					this.isValid = this.dataSource.formGroup.valid;
					this.rowErrors = Array.from(errors);
				});
			});

		this.subs.push(sub1, sub2);

		this.search();
	}

	search() {
		const query: IQueryNode = {};
		this.dataSource
			.setQueryNode(query)
			.fetchRows()
			.subscribe();
	}



	ngOnDestroy(): void {
		// Called once, before the instance is destroyed.
		// Add 'implements OnDestroy' to the class.
		this.subs.forEach(s => s.unsubscribe());
	}

	toggleColumnEdit() {
		const enabled = this.dataSource.columnEditToolsEnabled$.value;
		this.dataSource.columnEditToolsEnabled$.next(!enabled);
	}
}
