import { Injectable } from "@angular/core";
import { IAppOption } from "app/rightsline-app/components/header/profile-dropdown/profile-dropdown.models";
import { NotificationSortColumn, NotificationSortDirection, NotificationStatus } from "rl-common/components/notifications/notification.models";
import { NotificationService } from "rl-common/components/notifications/notification.service";
import { CharTypeId } from "rl-common/consts";
import { TokenScope } from "rl-common/models";
import { SessionService } from "rl-common/services/session.service";
import { of } from "rxjs";
import { concatMap, filter, switchMap, take, tap } from "rxjs/operators";
import { JwtTokenAclsResponse } from "../authentication/authentication.models";
import { AuthenticationService } from "../authentication/authentication.service";
import { SettingsService } from "../settings/settings.service";
import { TokenService } from "../token.service";

@Injectable({
	providedIn: "root"
})
export class TenantService {

	constructor(
		private readonly _sessionService: SessionService,
		private readonly _tokenService: TokenService,
		private readonly _authenticationService: AuthenticationService,
		private readonly _settingsService: SettingsService,
		private readonly _notificationService: NotificationService,
	) { }

	getApps(apps: [number, number, string][]) {
		return apps.map(x => {
			const appOption: IAppOption = {
				divId: x[0],
				appUrlId: x[1],
				name: x[2]
			};
			return appOption;
		});
	}

	switchTenant(divId: number, apps: IAppOption[]) {
		if (divId === this._sessionService.divId) {
			return of();
		}
		localStorage.removeItem("izendaToken");
		const currentApp = apps.find(x => x.divId === divId);

		return this._tokenService.assertToken()
			.pipe(
				switchMap((token) => this._authenticationService.switchApplication(token, this._sessionService.userName, currentApp.divId, currentApp.appUrlId)),
				take(1),
				filter(result => this._sessionService.assertScope(result.access_token, TokenScope.Admin)),
				tap(result => {
					this.setSession(result);
					this._sessionService.clearCache();
				}),
				concatMap(() => this._settingsService.switchDivs$()),
				concatMap(() => this._notificationService.getNotificationCount$(CharTypeId.All, NotificationStatus.Unread, NotificationSortColumn.Created,
					NotificationSortDirection.Descending)),
			);
	}

	private setSession(response: JwtTokenAclsResponse) {
		this._sessionService.setSession(response.access_token, response.refresh_token, response.acls_token);
	}
}
