<div id="universalSearch" class="universal-search" #searchForm>
	<form>
		<div class="input-group">
			<input type="text" placeholder="Search" [(ngModel)]="keywords" name="keywords" autocomplete="off"
				class="keywords form-control" (focus)="onFocused()" (focusout)="onFocusOut()" (ngModelChange)="search()"
				[class.keywords-has-text]="hasKeywords" #keywordsInput />
			<div class="input-group-append search-icon">
				<span class="input-group-text">
					<i *ngIf="!isLoading" class="far fa-search"></i>
					<i *ngIf="isLoading" class="fas fa-spinner fa-spin"></i>
				</span>
			</div>
		</div>
	</form>
</div>

<ng-template #rt let-r="result" let-t="term">
	<div class="typeahead-template p-1" [title]="r.title">
		<b>{{r.charTypeID | charTypeName}} {{r.recordID}}</b>:
		<ngb-highlight [result]="r.title" [term]="t"></ngb-highlight>
	</div>
</ng-template>