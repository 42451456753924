<nav>
	<ul class="list-unstyled">
		<li class="nav-item">
			<a class="nav-link" [routerLink]="linkHelper.home()" routerLinkActive="active"
				[routerLinkActiveOptions]="{exact: true}" fallbackPlacements="right" ngbTooltip="Home"
				tooltipClass="nav-item-tooltip" placement="end" [animation]="false" data-cy="SideNavigationHome"
				triggers="mouseenter:mouseleave click" aria-label="Home">
				<i class="far fa-home"></i>
			</a>
		</li>

		<ng-container *ngFor="let module of modules">
			<ng-container *ngIf="!module.requiredFeatureFlag">
				<li class="nav-item">
					<ng-container *ngIf="!module.children">
						<a class="nav-link" [routerLink]="module.path" routerLinkActive="active"
							fallbackPlacements="right" offset="0" ngbTooltip="{{module.sideBarLink}}"
							tooltipClass="nav-item-tooltip" placement="end" [animation]="false"
							triggers="mouseenter:mouseleave click"
							[attr.data-cy]="'SideNavigation' + module.sideBarLink"
							[attr.aria-label]="module.sideBarLink">
							<i class="far {{toCssClass(module)}}"></i>
						</a>
					</ng-container>
					<ng-container *ngIf="!!module.children">
						<a class="nav-link" type="button" routerLinkActive="active" [ngbPopover]="componentDropdownList"
							[popoverTitle]="'Components'" popoverClass="component-dropdown-popover" placement="end-top"
							triggers="mouseenter:click" [attr.data-cy]="'SideNavigation' + module.sideBarLink"
							[attr.aria-label]="module.sideBarLink">
							<i class="fa-layer-group far"></i>
						</a>
						<ng-template #componentDropdownList>
							<ul class="list-unstyled component-dropdown-list">
								<li *ngFor="let child of module.children">
									<a [routerLink]="child.path"
										class="component-dropdown-link d-block">{{child.sideBarLink}}</a>
								</li>
							</ul>
						</ng-template>
					</ng-container>
				</li>
			</ng-container>
			<ng-container *ngIf="!!module.requiredFeatureFlag">
				<ng-container *rlFeatureEnabled="module.requiredFeatureFlag">
					<ng-container *ngIf="!module.children">
						<li class="nav-item">
							<a class="nav-link" [routerLink]="module.path" routerLinkActive="active"
								[class.active]="isComponentActive" fallbackPlacements="right" offset="0"
								ngbTooltip="{{module.sideBarLink}}" tooltipClass="nav-item-tooltip" placement="end"
								[animation]="false" [attr.data-cy]="'SideNavigation' + module.sideBarLink"
								triggers="mouseenter:mouseleave click" [attr.aria-label]="module.sideBarLink">
								<i class="far {{toCssClass(module)}}"></i>
							</a>
						</li>
					</ng-container>
					<ng-container *ngIf="!!module.children && shouldShowComponents">
						<li class="nav-item" ngbDropdown #drop="ngbDropdown" display="dynamic" placement="right-top"
							(mouseover)="over(drop)" (mouseout)="out(drop)">
							<a class="nav-link" [class.active]="isComponentActive" tabindex="0" ngbDropdownToggle
								id="componentListPageMenu" role="button"
								[attr.data-cy]="'SideNavigation' + module.sideBarLink">
								<i class="fa-layer-group far"></i>
							</a>
							<div ngbDropdownMenu aria-labelledby="componentListPageMenu"
								class="component-dropdown-popover" (mouseout)="out(drop)">
								<div class="popover-arrow"></div>
								<h3 class="popover-header">Components</h3>
								<a class="close-button position-absolute cursor-pointer btn btn-close px-1"
									(click)="out(drop)" [attr.data-cy]="'SideNavigationCloseHoverMenu'"
									[attr.aria-label]="module.sideBarLink"><i class="far fa-times"></i></a>
								<ul class="list-unstyled component-dropdown-list popover-body mb-0">
									<li *ngFor="let child of module.children">
										<a ngbDropdownItem *ngIf="shouldShowComponent(child.charTypeId)"
											[routerLink]="child.path"
											[attr.data-cy]="'SideNavigation' + child.sideBarLink"
											class="component-dropdown-link d-block">{{child.sideBarLink}}</a>
									</li>
								</ul>
							</div>
						</li>
					</ng-container>
				</ng-container>
			</ng-container>
		</ng-container>

		<li *rlHasReadAccess="availableAcl" class="nav-item">
			<a class="nav-link" fallbackPlacements="right" ngbTooltip="Available" tooltipClass="nav-item-tooltip"
				placement="end" [animation]="false" (click)="openAvailable($event)" href="#"
				triggers="mouseenter:mouseleave click" data-cy="SideNavigationAvails" aria-label="Available">
				<img src="/assets/img/available_icon.png" alt="Available" style="width:30px;padding-left:5px;" />
			</a>
		</li>
	</ul>
</nav>

<nav>
	<ul class="list-unstyled mb-2">
		<li class="nav-item">
			<a class="nav-link cursor-pointer" ngbTooltip="Resource Center" tooltipClass="nav-item-tooltip"
				placement="end" [animation]="false" data-cy="SideNavigationResources" aria-label="Resource Center"
				triggers="mouseenter:mouseleave click">
				<i class="far fa-question-circle"></i>
			</a>
		</li>
		<li class="nav-item" *ngIf="(isRLAdmin$ | async) && conflictingVersions" (click)="toggleDebugModal()">
			<a class="nav-link cursor-pointer" ngbTooltip="Conflicting Versions" tooltipClass="nav-item-tooltip"
				placement="end" [animation]="false" aria-label="Conflicting Versions"
				triggers="mouseenter:mouseleave click">
				<i class="far fa-code-branch text-warning-dark"></i>
			</a>
		</li>
	</ul>
</nav>