import { ErrorHandler, Injectable } from "@angular/core";
import * as Sentry from "@sentry/angular-ivy";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

	private readonly _sentryErrorHandler: Sentry.SentryErrorHandler;

	constructor() {
		this._sentryErrorHandler = Sentry.createErrorHandler({
			showDialog: false,
		});
	}

	handleError(error: any): void {
		const chunkFailedMessage = /Loading chunk [\d]+ failed/;
		if (chunkFailedMessage.test(error.message)) {
			window.location.reload();
		}
		this._sentryErrorHandler.handleError(error);
	}
}