<header class="list__head">
	<h1 class="pt-1 d-flex align-items-center">
		Dashboard
		<span class="btn-icon btn-info btn-sm cursor-pointer text-medium" aria-label="Tooltip" role="button"
			data-pendo="tip-dashboard"><i class="far fa-question-circle"></i></span>
	</h1>
	<div class="d-inline-block">
		<button title="Add Widget" class="btn btn-icon btn-success" (click)="addWidget()" [disabled]="!dashboard">
			<i class="far fa-plus"></i>
		</button>
	</div>
</header>
<div [hidden]="!availsStatus.showAlertOnDashboard" class="p-3 pb-0">
	<rl-avails-status #availsStatus></rl-avails-status>
</div>
<div *ngIf="isLoading" class="p-3 text-center">
	<rl-loader [text]="'Loading Dashboard...'"></rl-loader>
</div>
<div *ngIf="!isLoading" class="dashboard d-flex flex-wrap p-2" cdkDropListGroup>
	<div class="dashboard-slot" cdkDropList [cdkDropListData]="i" *ngFor="let widget of widgets; let i = index"
		[style.width.%]="widget.size * 33.3333 || '100'" [ngClass]="{'isIzendaReport': widget.type == 5}">
		<rl-widget [options]="widget" [index]="i" cdkDrag [cdkDragData]="i" (cdkDragEntered)="entered($event)">
			<widget-handle>
				<span class="pt-1 drag__handle position-absolute" cdkDragHandle>
					<i class="far fa-ellipsis-v"></i>
				</span>
			</widget-handle>
			<div class="widget-placeholder" *cdkDragPlaceholder></div>
		</rl-widget>
	</div>
</div>
<div class="p-3" *ngIf="!isLoading">
	<button class="btn btn-link" (click)="saveDashboard()" [disabled]="isSaving">
		Save Dashboard Configuration <i class="far fa-spinner fa-spin ms-2" *ngIf="isSaving"></i>
	</button>
</div>